import React, { useState, useEffect } from 'react';
import './CSVKeywordUpload.css'; // Import your CSS file

const CSVKeywordUpload = () => {
  // State for Upload Form
  const [advertiserId, setAdvertiserId] = useState('5852409');
  const [fileType, setFileType] = useState('ad_creative');
  const [uploaderIp, setUploaderIp] = useState('69.69.69.69');
  const [uploaderOs, setUploaderOs] = useState('Mac');
  const [files, setFiles] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadPayload, setUploadPayload] = useState('');

  // State for Search Query Form
  const [searchQueryId, setSearchQueryId] = useState('71e2238b-b3a5-4484-9a50-8e53c3b0e165');
  const [searchQueryMethod, setSearchQueryMethod] = useState('status');
  const [searchQueryPayload, setSearchQueryPayload] = useState('');
  const [searchQueryStatus, setSearchQueryStatus] = useState('');

  // State for Search Finder Form
  const [searchFinderId, setSearchFinderId] = useState('71e2238b-b3a5-4484-9a50-8e53c3b0e165');
  const [searchFinderMethod, setSearchFinderMethod] = useState('data');
  const [searchFinderFormat, setSearchFinderFormat] = useState('json');
  const [searchFinderPayload, setSearchFinderPayload] = useState('');
  const [searchFinderStatus, setSearchFinderStatus] = useState('');

  // Update Upload Payload
  useEffect(() => {
    const payload = {
      advertiser_id: advertiserId,
      file_type: fileType,
      uploader_ip: uploaderIp,
      uploader_os: uploaderOs,
    };
    setUploadPayload(JSON.stringify(payload, null, 2));
  }, [advertiserId, fileType, uploaderIp, uploaderOs]);

  // Handle Upload Form Submission
  const handleUploadSubmit = (e) => {
    e.preventDefault();

    setUploadStatus('Uploading...');
    setProgress(0);
    setUploadPayload('');

    if (!files || files.length === 0) {
      setUploadStatus('Please select at least one file.');
      return;
    }

    const formData = new FormData();
    formData.append('advertiser_id', advertiserId);
    formData.append('file_type', fileType);
    formData.append('uploader_ip', uploaderIp);
    formData.append('uploader_os', uploaderOs);

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    const apiUrl = 'https://staging-api-v2.catalytics.io/user_upload';

    const xhr = new XMLHttpRequest();
    xhr.open('POST', apiUrl, true);
    xhr.setRequestHeader('Accept', 'application/json');

    xhr.upload.addEventListener('progress', (e) => {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100;
        setProgress(percentComplete);
      }
    });

    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        setUploadStatus('Upload successful!');
        setUploadPayload(JSON.stringify(response, null, 2));
        setProgress(0);
      } else {
        setUploadStatus(`Upload failed: ${xhr.statusText}`);
        setUploadPayload('');
      }
    };

    xhr.onerror = () => {
      setUploadStatus('An error occurred during the upload. Please try again.');
      setUploadPayload('');
    };

    xhr.send(formData);
  };

  // Update Search Query Payload
  useEffect(() => {
    const payload = {
      search_id: searchQueryId,
      method: searchQueryMethod,
    };
    setSearchQueryPayload(JSON.stringify(payload, null, 2));
  }, [searchQueryId, searchQueryMethod]);

  // Handle Search Query Form Submission
  const handleSearchQuerySubmit = (e) => {
    e.preventDefault();

    const payload = {
      search_id: searchQueryId,
      method: searchQueryMethod,
    };

    fetch('https://staging-api-v2.catalytics.io/context_search_query', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        setSearchQueryStatus(JSON.stringify(data, null, 2));
      })
      .catch((error) => {
        console.error('Error:', error);
        setSearchQueryStatus('An error occurred while making the request.');
      });
  };

  // Update Search Finder Payload
  useEffect(() => {
    const payload = {
      search_id: searchFinderId,
      method: searchFinderMethod,
      format: searchFinderFormat.trim() || undefined,
    };
    setSearchFinderPayload(JSON.stringify(payload, null, 2));
  }, [searchFinderId, searchFinderMethod, searchFinderFormat]);

  // Handle Search Finder Form Submission
  const handleSearchFinderSubmit = (e) => {
    e.preventDefault();

    const payload = {
      search_id: searchFinderId,
      method: searchFinderMethod,
      format: searchFinderFormat.trim() || undefined,
    };

    fetch('https://staging-api-v2.catalytics.io/context_search_finder', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        setSearchFinderStatus(JSON.stringify(data, null, 2));
      })
      .catch((error) => {
        console.error('Error:', error);
        setSearchFinderStatus('An error occurred while making the request.');
      });
  };

  return (
    <div>
      <h1>API Testing Form</h1>

      {/* Upload Files Form */}
      <div className="container">
        <h2>Upload Files</h2>
        <form onSubmit={handleUploadSubmit} encType="multipart/form-data">
          <label htmlFor="advertiser_id">Advertiser ID:</label>
          <input
            type="text"
            id="advertiser_id"
            name="advertiser_id"
            required
            value={advertiserId}
            onChange={(e) => setAdvertiserId(e.target.value)}
          />

          <label htmlFor="file_type">File Type:</label>
          <select
            id="file_type"
            name="file_type"
            value={fileType}
            onChange={(e) => setFileType(e.target.value)}
          >
            <option value="ad_creative">ad_creative</option>
            <option value="context_search">context_search</option>
          </select>

          <label htmlFor="uploader_ip">Uploader IP:</label>
          <input
            type="text"
            id="uploader_ip"
            name="uploader_ip"
            required
            value={uploaderIp}
            onChange={(e) => setUploaderIp(e.target.value)}
          />

          <label htmlFor="uploader_os">Uploader OS:</label>
          <input
            type="text"
            id="uploader_os"
            name="uploader_os"
            required
            value={uploaderOs}
            onChange={(e) => setUploaderOs(e.target.value)}
          />

          <label htmlFor="files">Select files to upload:</label>
          <input
            type="file"
            id="files"
            name="files"
            multiple
            accept="image/*,text/csv"
            required
            onChange={(e) => setFiles(e.target.files)}
          />

          <div className="progress-bar">
            <div
              className="progress-bar-inner"
              style={{ width: `${progress}%` }}
            ></div>
          </div>

          <button type="submit">Upload Files</button>
        </form>

        <div id="uploadStatus">{uploadStatus}</div>
        {/* Upload Response */}
        <label>Upload Response:</label>
        <div id="uploadPayload" className="output-payload">
          <pre>{uploadPayload}</pre>
        </div>
      </div>

      {/* Search Query Form */}
      <div className="container">
        <h2>Search Query Test</h2>
        <form onSubmit={handleSearchQuerySubmit}>
          <label htmlFor="searchQueryId">Search ID:</label>
          <input
            type="text"
            id="searchQueryId"
            name="searchQueryId"
            value={searchQueryId}
            onChange={(e) => setSearchQueryId(e.target.value)}
          />

          <label htmlFor="searchQueryMethod">Method:</label>
          <select
            id="searchQueryMethod"
            name="searchQueryMethod"
            value={searchQueryMethod}
            onChange={(e) => setSearchQueryMethod(e.target.value)}
          >
            <option value="status">Status</option>
            <option value="data">Data</option>
          </select>

          {/* Output Payload */}
          <label>Final Payload:</label>
          <div id="searchQueryPayload" className="output-payload">
            <pre>{searchQueryPayload}</pre>
          </div>

          <button type="submit">Send Request</button>
        </form>
        <pre id="searchQueryStatus">{searchQueryStatus}</pre>
      </div>

      {/* Search Finder Form */}
      <div className="container">
        <h2>Search Finder Test</h2>
        <form onSubmit={handleSearchFinderSubmit}>
          <label htmlFor="searchFinderId">Search ID:</label>
          <input
            type="text"
            id="searchFinderId"
            name="searchFinderId"
            value={searchFinderId}
            onChange={(e) => setSearchFinderId(e.target.value)}
          />

          <label htmlFor="searchFinderMethod">Method:</label>
          <select
            id="searchFinderMethod"
            name="searchFinderMethod"
            value={searchFinderMethod}
            onChange={(e) => setSearchFinderMethod(e.target.value)}
          >
            <option value="data">Data</option>
            <option value="status">Status</option>
          </select>

          <label htmlFor="searchFinderFormat">Format:</label>
          <input
            type="text"
            id="searchFinderFormat"
            name="searchFinderFormat"
            value={searchFinderFormat}
            onChange={(e) => setSearchFinderFormat(e.target.value)}
          />

          {/* Output Payload */}
          <label>Final Payload:</label>
          <div id="searchFinderPayload" className="output-payload">
            <pre>{searchFinderPayload}</pre>
          </div>

          <button type="submit">Send Request</button>
        </form>
        <pre id="searchFinderStatus">{searchFinderStatus}</pre>
      </div>
    </div>
  );
};

export default CSVKeywordUpload;