import React, { useEffect, useRef, useState } from 'react';
//import CustomDatePicker from './shared/CustomDatePicker';
import CustomDatePicker from '../CustomDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-calendar/dist/Calendar.css';
import './date-range-selector.css';

const DateRangeSelector = () => {
  //const [startDate, setStartDate] = useState(`${new Date().getFullYear()}-${new Date().getMonth()+1}-01`);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="date-range-selector">
      {console.log('startDate: ' + startDate)}
      {console.log('endDate: ' + endDate)}
      <CustomDatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        //value={startDate}
      />
      <CustomDatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        //value={endDate}
      />
    </div>
  );
};

export default DateRangeSelector;
