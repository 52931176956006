import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SegmentsActive } from '../../../assets/images/Columns.svg';
import { ReactComponent as Segments } from '../../../assets/images/Group.svg';
export default function SegmentsFilter({ gridRef }) {
  const [showSegments, setShowSegments] = useState(false);
  return (
    <>
      <div
        style={{
          display: 'flex',
          paddingRight: 15,
          paddingLeft: 15,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          width: '100%',
          backgroundColor: showSegments ? 'black' : '#ECECF0',
        }}
        onClick={() => setShowSegments(!showSegments)}
      >
        {showSegments ? <SegmentsActive /> : <Segments />}
        <span
          style={{
            fontSize: 6,
            marginTop: 7,
            color: '#8C96A7',
          }}
        >
          Segments
        </span>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 44,
          left: -90,
          zIndex: 1000,
          width: '125%',
          backgroundColor: '#ECECF0',
          boxShadow: '1px 1px 5px grey',
        }}
      >
        {showSegments && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              padding: 5,
            }}
          >
            <div>placeholder</div>
          </div>
        )}
      </div>
    </>
  );
}
