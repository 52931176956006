import React from 'react';

function GoToLink() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 15.5068H15.836V14.4925H1.01462V4.52769H14.1537L11.8026 6.87915L12.4726 7.54827L16 4.02026L12.4726 0.49313L11.8029 1.16257L14.1534 3.51313H0V15.5068Z"
        fill="#010002"
      />
    </svg>
  );
}

export default GoToLink;
