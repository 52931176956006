const StripeProducts = () => {
  return [
    {
      name: 'Plan 1',
      price: 200.0,
      priceId: 'price_1Kj8eYH9Krw0xqVK48aZSXGt',
    },
    {
      name: 'Plan 2',
      price: 500.0,
      priceId: 'price_1Kj8eRH9Krw0xqVKxSc1DSQB',
    },
    {
      name: 'Plan 3',
      price: 1000.0,
      priceId: 'price_1Kj8eLH9Krw0xqVKtfR1Cn7N',
    },
    {
      name: 'Plan 4',
      price: 3000.0,
      priceId: 'price_1Kj8e8H9Krw0xqVK2TrTpcsw',
    },
    {
      name: 'Plan 5',
      price: 5000.0,
      priceId: 'price_1Kj8e2H9Krw0xqVKykWZCyF1',
    },
    {
      name: 'Plan 6',
      price: 10000.0,
      priceId: 'price_1Kj8cMH9Krw0xqVK6y4NrOT7',
    },
  ];
};

export default StripeProducts;
