import React, { useState, useEffect, useContext } from 'react';
//import {AdvertiserContext} from '../../../AdvertiserContext'
//import S3Upload from '../../../S3Upload';
import StepsBar from '../steps-bar/StepsBar';
//import {useDropzone} from 'react-dropzone';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import ReactTooltip from 'react-tooltip';
import './creative-upload.css';
import { CampaignContext } from '../../../../context/CampaignContext';
//import {AccountContext} from "../../../../context/AccountContext";
import { uploadData, getUrl, remove } from 'aws-amplify/storage';
import { Link } from 'react-router-dom';
import NeedsHelpAds from './needs-help-ads/NeedsHelpAds';
import Delete from '../../../../assets/icons/Delete';
import BuildYourOwn from '../../../../assets/icons/BuildYourOwn';
import Template from '../../../../assets/icons/Template';
import Custom from '../../../../assets/icons/Custom';
import { PopupButton } from '@typeform/embed-react';
import adSizes from '../../../../data/AdSizes';

/*const testAd = {
    url: "https://m.media-amazon.com/images/M/MV5BMTM2OTk0MDU4OV5BMl5BanBnXkFtZTcwNDA1NDI5NQ@@._V1_QL75_UX500_CR0,47,500,281_.jpg",
    name: "Test Ad",
    width: 200,
    height: 200,
    landingPageProtocol: "https://",
    landingPage: "https://www.ferroic.com/technology",
    landingPageUri: "https://www.ferroic.com/technology",

}*/

//const ads = [testAd, testAd, testAd]

const CreativeUpload = () => {
  const {
    ads,
    addToAds,
    removeFromAds,
    updateAdProperty,
    updateAdUrl,
    adsHaveValidLandingPages,
    adsHaveValidAdSizes,
    checkAdsHaveValidAdSizes,
    checkIsValidUrl,
    updateAdDimensions,
  } = useContext(CampaignContext);
  //const {accountId} = useContext(AccountContext);
  const [needsHelp, setNeedsHelp] = useState(false);
  const [uploadAlert, setUploadAlert] = useState('');

  const sizeHasBeenUploaded = (index) => {
    let found = false;
    ads.forEach((ad) => {
      if (
        adSizes()[index]['width'] === ad['width'] &&
        adSizes()[index]['height'] === ad['height']
      ) {
        found = true;
      }
    });
    return found;
  };

  useEffect(() => {
    let adsCopy = ads;
    if (adsCopy.length > 0) {
      for (const [fileIndex, fileWithMeta] of adsCopy.entries()) {
        if (fileWithMeta['key'] === undefined) {
          continue;
        }

        async function getAdUrlKey(index, file) {
          let url = await getUrl(file['key'], {
            accessLevel: 'private',
            contentType: file['type'],
          });
          updateAdUrl(index, url);
        }

        getAdUrlKey(fileIndex, fileWithMeta);
      }
    }
  }, []);

  const needsHelpHandler = (status) => {
    setNeedsHelp(status);
  };

  const removeAd = async (file) => {
    removeFromAds(file);
    await remove(file['key'], { accessLevel: 'private' });
  };

  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === 'done') {
      if (meta.type.indexOf('image') > -1) {
        if (meta.size > 150000) {
          setUploadAlert(
            'Some files could not be uploaded. Please make sure the image files are below 150 KB'
          );
          remove();
          return;
        }
        let found = false;
        for (const adSize of adSizes()) {
          if (
            meta.height === adSize['height'] &&
            meta.width === adSize['width']
          ) {
            found = true;
          }
        }
        if (!found) {
          //addToAlert(meta.name)
          setUploadAlert(
            'Some files could not be uploaded. Please make sure the image files match any of the required dimensions. Dimensions found for ' +
              meta.name +
              ': ' +
              meta.width +
              'x' +
              meta.height +
              ''
          );
          remove();
        }
      }
      if (meta.type.indexOf('zip') > -1) {
        if (meta.size > 150000) {
          setUploadAlert(
            'Some files could not be uploaded. Please make sure the zip files are below 150 KB'
          );
          remove();
          return;
        }
      }
      if (meta.type.indexOf('video') > -1) {
        if (meta.size > 1000000) {
          setUploadAlert(
            'Some files could not be uploaded. Please make sure the video files are below 1 MB'
          );
          remove();
        }
      }
    }
  };

  const handleSubmit = async (files, allFiles) => {
    setUploadAlert('');
    //console.log(files.map(f => f.meta))
    let newFiles = [];
    for await (let fileWithMeta of files) {
      await uploadData(fileWithMeta.meta.name, fileWithMeta.file, {
        accessLevel: 'private',
        contentType: fileWithMeta.meta.type,

      })
        .result.then(async (result) => {
          let fileToSave = fileWithMeta.meta;
          fileToSave['key'] = result['key'];
          fileToSave['eTag'] = result['eTag'];
          fileToSave['url'] = await getUrl(fileToSave['key'], {
            accessLevel: 'private',
            // expires: 86400 * 7,
            contentType: fileWithMeta.meta.type,
          });
          newFiles.push(fileToSave);
        })
        .catch((err) => console.log(err));
    }
    addToAds(newFiles);
    allFiles.forEach((f) => f.remove());
  };

  const getIndexForAdDimensions = (width, height) => {
    for (const [adSizeIndex, adSize] of adSizes().entries()) {
      if (adSize['width'] === width && adSize['height'] === height) {
        return adSizeIndex;
      }
    }
    return '';
  };

  return !needsHelp ? (
    <div className="creative-upload">
      <div className="step-description">
        <h2>Upload Ad Creative &amp; Assign a Landing Page</h2>
        <p>Upload up to 12 ads at a time</p>
      </div>
      <StepsBar step="2" />
      <ReactTooltip backgroundColor="#f3f3f3" textColor="#000000" />
      {
        <Dropzone
          onChangeStatus={handleChangeStatus}
          onSubmit={handleSubmit}
          accept="image/jpeg, image/png, image/gif, video/mp4, application/zip, application/x-zip-compressed, application/x-compressed, multipart/x-zip"
          inputContent={(files, extra) =>
            extra.reject ? 'Image files only' : 'Drag Files or Click to Browse'
          }
          styles={{
            dropzone: { minHeight: 200, maxHeight: 250 },
            dropzoneReject: {
              borderColor: 'red',
              backgroundColor: '#DAA',
            },
            inputLabel: (files, extra) =>
              extra.reject ? { color: 'red' } : { color: '#011526' },
            inputLabelWithFiles: (files, _) => ({
              color: '#011526',
            }),
            submitButton: (files, _) => ({
              backgroundColor: '#011526',
              lineHeight: '20px',
              letterSpacing: '0.11em',
              fontFamily: 'Catalytics Regular',
              textTransform: 'uppercase',
              borderRadius: '0px',
              padding: '1em 28px',
              fontSize: '12px',
              fontWeight: '500',
              fontStyle: 'normal',
            }),
          }}
          submitButtonContent={'Add To Campaign'}
        />
      }
      {uploadAlert !== '' ? (
        <div className="upload-alert">{uploadAlert}</div>
      ) : (
        ''
      )}

      <div className="uploaded-ads-container">
        <h4>Uploaded Ads ({ads.length})</h4>
        <p className="section-description">
          Your uploaded ads will appear below. Assign a landing page for each
          creative uploaded.
        </p>
        {ads.length > 0 ? (
          <div className="uploaded-ads">
            <table className="default-table">
              <colgroup>
                <col className="ad-image-col" />
                <col className="file-name" />
                <col className="dimension" />
                <col className="link" />
                <col className="action" />
              </colgroup>
              <thead>
                <tr>
                  <th>Uploaded Ad</th>
                  <th>File Name</th>
                  <th>Ad Dimension</th>
                  <th>Landing Page - The destination URL for ad clicks</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {ads.map((ad, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="ad-number-image">
                          <span>{index + 1}.</span>
                          <div className="ad-image">
                            <div className="ad-image-inner">
                              {ad.name.substring(ad.name.length - 4) ===
                              '.zip' ? (
                                <a href={ad.url} target="_blank">
                                  {ad.name}
                                </a>
                              ) : (
                                <img src={ad.url} alt={ad.name} />
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{ad.name}</td>
                      <td>
                        {ad.name.substring(ad.name.length - 4) === '.zip' ? (
                          <select
                            className={
                              ad['width'] === undefined ||
                              ad['height'] === undefined
                                ? 'alert'
                                : ''
                            }
                            onChange={(e) =>
                              updateAdDimensions(
                                index,
                                adSizes()[e.target.value]['width'],
                                adSizes()[e.target.value]['height']
                              )
                            }
                            defaultValue={
                              ad['width'] !== undefined &&
                              ad['height'] !== undefined
                                ? getIndexForAdDimensions(
                                    ad['width'],
                                    ad['height']
                                  )
                                : ''
                            }
                          >
                            <option>Select</option>
                            {adSizes().map((adSize, adSizeIndex) => {
                              return (
                                <option key={adSizeIndex} value={adSizeIndex}>
                                  {adSize['width']}x{adSize['height']}
                                </option>
                              );
                            })}
                          </select>
                        ) : ad['width'] !== undefined &&
                          ad['height'] !== undefined ? (
                          <span>
                            {ad['width']}x{ad['height']}
                          </span>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="bordered-input">
                        <div className="url-input-container">
                          <div className="url-protocol-container">
                            <select
                              onChange={(e) =>
                                updateAdProperty(
                                  index,
                                  'landingPageProtocol',
                                  e.target.value
                                )
                              }
                              defaultValue={
                                ad['landingPageProtocol'] !== undefined
                                  ? ad['landingPageProtocol']
                                  : ''
                              }
                            >
                              <option>https://</option>
                              <option>http://</option>
                            </select>
                          </div>
                          <div className="url-full-container">
                            <input
                              type="text"
                              name="landing-page"
                              className={
                                ad['landingPage'] !== undefined &&
                                ad['landingPage'] !== '' &&
                                checkIsValidUrl(ad['landingPage'])
                                  ? ''
                                  : 'alert'
                              }
                              placeholder="www.example.com"
                              defaultValue={
                                ad['landingPageUri'] !== undefined
                                  ? ad['landingPageUri']
                                  : ''
                              }
                              onChange={(e) =>
                                updateAdProperty(
                                  index,
                                  'landingPageUri',
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td className="action">
                        <button onClick={() => removeAd(ad)}>
                          <Delete />
                          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                        <path
                                                            d="M380.2 453.7c5.703 6.75 4.859 16.84-1.891 22.56C375.3 478.7 371.7 480 368 480c-4.547 0-9.063-1.938-12.23-5.657L192 280.8l-163.8 193.6C25.05 478.1 20.53 480 15.98 480c-3.641 0-7.313-1.25-10.31-3.781c-6.75-5.719-7.594-15.81-1.891-22.56l167.2-197.7L3.781 58.32c-5.703-6.75-4.859-16.84 1.891-22.56c6.75-5.688 16.83-4.813 22.55 1.875L192 231.2l163.8-193.6c5.703-6.688 15.8-7.563 22.55-1.875c6.75 5.719 7.594 15.81 1.891 22.56l-167.2 197.7L380.2 453.7z"/>
                                                    </svg> */}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="ad-sizes-container">
        <p>Dimensions (in pixels) can be:</p>
        <div className="ad-sizes">
          {adSizes().map((adSize, adSizeIndex) => {
            return (
              <div key={adSizeIndex} className="ad-size">
                {sizeHasBeenUploaded(adSizeIndex) ? (
                  <div className="size-uploaded">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M480.1 128.1l-272 272C204.3 405.7 198.2 408 192 408s-12.28-2.344-16.97-7.031l-144-144c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L192 350.1l255-255c9.375-9.375 24.56-9.375 33.94 0S490.3 119.6 480.1 128.1z" />
                    </svg>
                  </div>
                ) : (
                  ''
                )}
                <img src={adSize['img']} alt={adSize['name']} />
                <div className="ad-size-name">{adSize['name']}</div>
                <div className="ad-size-size">
                  {adSize['width']} x {adSize['height']} px
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="help-container">
        <h2>Don’t have creative ready?</h2>
        <p className="subtitle">
          That’s no problem, we’ve got three options to help!
        </p>
        <div className="info-box-container">
          <button onClick={needsHelpHandler}>
            <div className="info-box">
              <div className="icon-section">
                <BuildYourOwn />
              </div>
              <div className="info-section">
                <h2>Build Your Own</h2>
                <p className="description">
                  Use the Catalytics Ad Generator to create ads quickly and
                  easily.
                </p>
              </div>
            </div>
          </button>
          <div className="info-box">
            <div className="icon-section">
              <Template />
            </div>
            <div className="info-section">
              <h2>Template</h2>
              <p className="description">
                Choose from our pre-made templates. We'll build your ads and
                send them back to you for approval.
              </p>
            </div>
          </div>
          <PopupButton id="v6m9W3Sp" className="info-box info-box-button">
            <div className="icon-section">
              <Custom />
            </div>
            <div className="info-section">
              <h2>Custom</h2>
              <p className="description">
                Work with the design and <br />
                copywriting team to create
                <br /> completely custom static
                <br /> or animated ads.
              </p>
            </div>
          </PopupButton>
        </div>
      </div>

      <div className="step-buttons-container">
        <div className="step-button-back">
          <Link className="button secondary" to="/create-campaign/0">
            Previous
          </Link>
        </div>

        {ads.length > 0 && adsHaveValidLandingPages && adsHaveValidAdSizes ? (
          <div className="step-button-next">
            <Link className="button" to="/create-campaign/2">
              Next
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  ) : (
    <NeedsHelpAds needsHelpHandler={needsHelpHandler} />
  );
};

/*<form id='creative_upload'>
<h1>Select and upload the ads you would like to serve</h1>
<p style={{textDecoration:"underline"}}>Ad ("creative") Guidelines</p>
<h2>Ad dimensions (in pixels) can be:</h2>
<ul>
<li>300x250</li>
<li>300x600</li>
<li>720x90</li>
<li>468x60</li>
<li>120x600</li>
</ul>
<h2>Other creative criteria</h2>
<ul>
<li>Files must be either jpg or mp4</li>
<li>Maximum file size (per jpg) is 150 kb</li>
<li>Maximum file size per mp4 is 1 mb</li>
<li>Ad content must comply with IAB standards for <a href="https://www.iab.com/wp-content/uploads/2019/04/IABNewAdPortfolio_LW_FixedSizeSpec.pdf">acceptable content</a>, including, but not limited to:
<ul>
<li>Ads must clearly display the advertiser and offering</li>
<li>The click-through destination for the ad must be a quality user experience controlled by the advertiser</li>
<li>There must be a clear connection between the landing page and the message in the ad</li>
</ul>
</li>
</ul>
<div id="upload_controls">
<S3Upload></S3Upload>
</div>
</form>*/

export default CreativeUpload;
