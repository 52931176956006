import axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../data/Urls';


let targetingProfileResponse = {
  status: false,
  error: '',
  info: {},
};

const CreateTargetingProfile = async (
  advertiserId,
  lineItemId,
  profileParams
) => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  const request = {
    line_item_id: lineItemId,
    advertiser_id: advertiserId,
    profile_params: profileParams,
  };

  await axios
    .post(getCatalyticsApiUrl() + 'targeting-profile', request, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${accessToken}`,
      },
    })
    .then(async (responseCreate) => {
      if (
        responseCreate.data === undefined ||
        responseCreate.data.body === undefined
      ) {
        targetingProfileResponse.error =
          'Empty response from POST targeting profile service';
        ApiException(
          targetingProfileResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }

      let bodyCreate = JSON.parse(responseCreate['data']['body']);
      if (bodyCreate['profile_id'] === undefined) {
        targetingProfileResponse.error =
          'Invalid response from POST targeting profile service';
        ApiException(
          targetingProfileResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      targetingProfileResponse.status = true;
      targetingProfileResponse.info = bodyCreate;
    })
    .catch(function (error) {
      targetingProfileResponse.error = error;
      ApiException(
        targetingProfileResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        request,
        {}
      );
      return;
    });
  return targetingProfileResponse;
};

export default CreateTargetingProfile;
