import imgSize1 from '../assets/images/ad-sizes/size-1.svg';
import imgSize2 from '../assets/images/ad-sizes/size-2.svg';
import imgSize3 from '../assets/images/ad-sizes/size-3.svg';
import imgSize4 from '../assets/images/ad-sizes/size-4.svg';
import imgSize5 from '../assets/images/ad-sizes/size-5.svg';
import imgSize6 from '../assets/images/ad-sizes/size-6.svg';
import imgSize7 from '../assets/images/ad-sizes/size-7.svg';
import imgSize8 from '../assets/images/ad-sizes/size-8.svg';
import imgSize9 from '../assets/images/ad-sizes/size-9.svg';
import imgSize10 from '../assets/images/ad-sizes/size-10.svg';
import imgSize11 from '../assets/images/ad-sizes/size-11.svg';
import imgSize12 from '../assets/images/ad-sizes/size-12.svg';
import imgSize13 from '../assets/images/ad-sizes/size-13.svg';
import imgSize14 from '../assets/images/ad-sizes/size-14.svg';
import imgSize15 from '../assets/images/ad-sizes/size-15.svg';
import imgSize16 from '../assets/images/ad-sizes/size-16.svg';
import imgSize17 from '../assets/images/ad-sizes/size-17.svg';
import imgSize18 from '../assets/images/ad-sizes/size-18.svg';

const adSizes = () => {
  return [
    { name: 'Medium Rectangle', img: imgSize1, width: 300, height: 250 },
    { name: 'Large Rectangle', img: imgSize2, width: 336, height: 280 },
    { name: 'Leaderboard', img: imgSize3, width: 728, height: 90 },
    { name: 'Mobile Leaderboard', img: imgSize4, width: 320, height: 50 },
    { name: 'Large mobile', img: imgSize5, width: 320, height: 100 },
    { name: 'Half Page', img: imgSize6, width: 300, height: 600 },
    { name: 'Wide Skyscrapper', img: imgSize7, width: 160, height: 600 },
    { name: 'Skyscrapper', img: imgSize8, width: 120, height: 600 },
    { name: 'Mobile Banner', img: imgSize9, width: 300, height: 50 },
    { name: 'Square', img: imgSize10, width: 250, height: 250 },
    { name: 'Small Square', img: imgSize11, width: 200, height: 200 },
    { name: 'Main Banner', img: imgSize12, width: 468, height: 60 },
    { name: 'Portrait', img: imgSize13, width: 300, height: 1050 },
    { name: 'Billboard', img: imgSize14, width: 970, height: 250 },
    { name: 'Large Leaderboard', img: imgSize15, width: 970, height: 90 },
    { name: 'Half Banner', img: imgSize16, width: 234, height: 60 },
    { name: 'Vertical Banner', img: imgSize17, width: 120, height: 240 },
    { name: 'Small Rectangle', img: imgSize18, width: 180, height: 250 },
  ];
};

export default adSizes;
