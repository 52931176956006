import React from 'react';

function LogoutIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_287_2520)">
        <path
          d="M8.00003 15.9461C12.4112 15.9461 16 12.3573 16 7.94609C16 4.84679 14.2272 2.15553 11.6435 0.827698V2.48939C13.4029 3.6681 14.5639 5.67388 14.5639 7.94609C14.5639 11.5655 11.6195 14.5101 8.00006 14.5101C4.38063 14.5101 1.43608 11.5655 1.43608 7.94609C1.43608 5.67388 2.59696 3.6681 4.35651 2.48939V0.827698C1.77284 2.15553 0 4.84679 0 7.94609C0 12.3573 3.58887 15.9461 8.00003 15.9461Z"
          fill="#8C96A7"
        />
        <path
          d="M8.00004 0.053833C7.57392 0.053833 7.22861 0.399267 7.22861 0.825294V1.429V8.09148C7.22861 8.51751 7.57392 8.86294 8.00004 8.86294C8.42616 8.86294 8.7715 8.51751 8.7715 8.09148V1.429V0.825294C8.77147 0.399267 8.42613 0.053833 8.00004 0.053833Z"
          fill="#8C96A7"
        />
      </g>
      <defs>
        <clipPath id="clip0_287_2520">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoutIcon;
