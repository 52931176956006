import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../data/Urls';

let trackingPixelResponse = {
  status: false,
  error: '',
  info: {},
};

const CreateTrackingPixel = async (advertiserId, name) => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  const request = {
    advertiserId: advertiserId,
    name: name,
  };
  await axios
    .post(getCatalyticsApiUrl() + 'tracking-pixel', request, {
      headers: {
        Authorization: `Basic ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(async (responseCreate) => {
      if (
        responseCreate.data === undefined ||
        responseCreate.data.body === undefined
      ) {
        trackingPixelResponse.error =
          'Empty response from POST tracking pixel service';
        ApiException(
          trackingPixelResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }

      let bodyCreate = JSON.parse(responseCreate['data']['body']);
      if (bodyCreate['universal_pixel'] === undefined) {
        trackingPixelResponse.error =
          'Invalid response from POST tracking pixel service (no universal pixel property on response)';
        ApiException(
          trackingPixelResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      trackingPixelResponse.status = true;
      trackingPixelResponse.info = bodyCreate;
    })
    .catch(function (error) {
      trackingPixelResponse.error = error;
      ApiException(
        trackingPixelResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        request,
        {}
      );
      return;
    });
  return trackingPixelResponse;
};

const TrackingPixel = async (advertiserId, name) => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  const request = { advertiserId: advertiserId };
  await axios
    .get(getCatalyticsApiUrl() + 'tracking-pixel', {
      params: {
        advertiserId: advertiserId,
      },
      headers: {
        Authorization: `Basic ${accessToken}`,
      },
    })
    .then(async (response) => {
      if (response['data'] === null) {
        trackingPixelResponse.error =
          'Empty response from GET tracking pixel service';
        ApiException(
          trackingPixelResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          response
        );
        return;
      }
      let body = JSON.parse(response['data']['body']);
      if (body['Error'] === undefined) {
        trackingPixelResponse.status = true;
        trackingPixelResponse.info = body;
        return;
      }
      trackingPixelResponse = await CreateTrackingPixel(advertiserId, name);
    })
    .catch(function (error) {
      trackingPixelResponse.error = error;
      ApiException(
        trackingPixelResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        request,
        {}
      );
    });
  return trackingPixelResponse;
};

export default TrackingPixel;
