import React from 'react';

function UserIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12" r="10.5" stroke="#131B23" />
      <circle cx="12.5" cy="12" r="8" fill="#131B23" />
      <path
        d="M9.50366 12.6502C8.69335 13.4605 8.4771 14.6384 8.85313 15.6464L12.4999 11.9996C11.4919 11.6242 10.314 11.8405 9.50366 12.6502Z"
        fill="white"
      />
      <path
        d="M8.32953 11.48C7.51921 12.2897 7.30297 13.4682 7.67899 14.4756L11.3257 10.8289C10.3178 10.4534 9.13925 10.6697 8.32953 11.48Z"
        fill="white"
      />
      <path
        d="M15.496 11.3489C16.3063 10.5385 16.5226 9.3606 16.1466 8.35266L12.5004 11.9994C13.5078 12.3754 14.6863 12.1592 15.496 11.3489Z"
        fill="white"
      />
      <path
        d="M16.6734 12.5183C17.4837 11.708 17.6999 10.53 17.3239 9.52209L13.6772 13.1688C14.6851 13.5448 15.8631 13.3286 16.6734 12.5183Z"
        fill="white"
      />
      <path
        d="M11.85 9.00324C11.0397 8.19292 9.86176 7.97667 8.85382 8.3527L12.5005 11.9994C12.8766 10.9915 12.6603 9.81295 11.85 9.00324Z"
        fill="white"
      />
      <path
        d="M13.023 7.82953C12.2127 7.01921 11.0341 6.80297 10.0268 7.17899L13.6735 10.8257C14.0489 9.81778 13.8327 8.63925 13.023 7.82953Z"
        fill="white"
      />
      <path
        d="M13.151 14.9963C13.9613 15.8066 15.1393 16.0229 16.1472 15.6468L12.5005 12.0001C12.1244 13.0081 12.3407 14.1866 13.151 14.9963Z"
        fill="white"
      />
      <path
        d="M11.9802 16.17C12.7905 16.9803 13.9685 17.1966 14.9764 16.8206L11.3297 13.1738C10.9537 14.1818 11.1699 15.3597 11.9802 16.17Z"
        fill="white"
      />
    </svg>
  );
}

export default UserIcon;
