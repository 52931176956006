import React from 'react';
import './radiobox-style.css';

function Radiobox({ label, ...props }) {
  return (
    <label className={`radiobox-container ${label ? '' : 'no-label'}`}>
      {label && label}
      <input type="radio" {...props} />
      <span className="checkmark">
        <span className="radiobox-marker" />
      </span>
    </label>
  );
}

export default Radiobox;
