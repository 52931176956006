import axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from 'data/Urls';

let lineItemResponse = {
  status: false,
  error: '',
  info: {},
};

const CreateLineItem = async (
  advertiserId,
  name,
  insertionOrderId,
  creatives,
  pixels,
  startDate,
  endDate,
  lifetimeBudget,
  dailyBudget
) => {
  //const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  const request = {
    name: name,
    advertiser_id: advertiserId,
    state: 'inactive',
    manage_inventory: true,
    creatives: creatives,
    insertion_orders: [{ id: insertionOrderId }],
    revenue_value: 10.0,
    pixels: pixels,
    budget_intervals: [
      {
        start_date: startDate,
        end_date: null, //removed to always inherit from insertion order
        code: null,
        lifetime_budget: lifetimeBudget,
        daily_budget: dailyBudget,
      },
    ],
  };
  await axios
    .post(getCatalyticsApiUrl() + 'line-item', request, {
      headers: {
        Authorization: `Basic ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(async (responseCreate) => {
      if (
        responseCreate.data === undefined ||
        responseCreate.data.body === undefined
      ) {
        lineItemResponse.error = 'Empty response from POST line item service';
        ApiException(
          lineItemResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }

      let bodyCreate = JSON.parse(responseCreate['data']['body']);
      if (bodyCreate['line_items'] === undefined) {
        lineItemResponse.error = 'Invalid response from POST line item service';
        ApiException(
          lineItemResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      if (bodyCreate['line_items']['id'] === undefined) {
        lineItemResponse.error =
          'Line item response does not contain a line item id';
        ApiException(
          lineItemResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      lineItemResponse.status = true;
      lineItemResponse.info = bodyCreate;
    })
    .catch(function (error) {
      lineItemResponse.error = error;
      ApiException(
        lineItemResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        request,
        {}
      );
      return;
    });
  return lineItemResponse;
};

export default CreateLineItem;
