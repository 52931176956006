import axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../data/Urls';

const GetReport = async (advertiserId) => {
  let reportResponse = {
    status: false,
    error: '',
    info: {},
  };
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;

  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const request = {
    start_date: firstDay.toLocaleDateString('en-CA'),
    end_date: lastDay.toLocaleDateString('en-CA'),
    report_type: 'network_analytics',
    advertiser_id: advertiserId,
  };
  await axios
    .get(getCatalyticsApiUrl() + 'reports', {
      params: {
        start_date: firstDay.toLocaleDateString('en-CA'),
        end_date: lastDay.toLocaleDateString('en-CA'),
        report_type: 'network_analytics',
        advertiser_id: advertiserId,
      },
      headers: {
        Authorization: `Basic ${accessToken}`,
      },
    })
    .then(async (response) => {
      if (response['data'] === null) {
        reportResponse.error = 'Empty response from GET report';
        ApiException(
          reportResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          response
        );
        return;
      }
      let body = JSON.parse(response['data']['body']);
      if (body['report_data'] === undefined) {
        reportResponse.error = 'Invalid response from GET report';
        ApiException(
          reportResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          body
        );
        return;
      }
      reportResponse.status = true;
      reportResponse.info = body['report_data'];
    })
    .catch(function (error) {
      reportResponse.error = error;
      ApiException(
        reportResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        request,
        {}
      );
    });
  return reportResponse;
};

export default GetReport;
