import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../../data/Urls';

let invoiceResponse = {
  status: false,
  error: '',
  info: {},
};

const GetInvoice = async (invoiceId) => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  await axios
    .get(getCatalyticsApiUrl() + 'stripe-invoice', {
      params: {
        invoiceId: invoiceId,
        env:
          process.env.REACT_APP_BUILD_ENV === 'development'
            ? 'development'
            : '',
      },
      headers: {
        Authorization: `Basic ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(async (responseGet) => {
      if (
        responseGet.data === undefined ||
        responseGet.data.body === undefined
      ) {
        invoiceResponse.error = 'Empty response from GET invoice';
        ApiException(
          invoiceResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          responseGet
        );
      }
      let body = JSON.parse(responseGet['data']['body']);
      if (
        body['invoiceInfo'] === undefined ||
        body['invoiceInfo']['id'] === undefined
      ) {
        invoiceResponse.error = 'Invoice info not returned from GET invoice';
        ApiException(
          invoiceResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          responseGet
        );
      }
      invoiceResponse.status = true;
      invoiceResponse.info = body['invoiceInfo'];
    })
    .catch(function (error) {
      invoiceResponse.error = error;
      ApiException(
        invoiceResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        {},
        {}
      );
    });
  return invoiceResponse;
};

export default GetInvoice;
