import React, { useState, useContext } from 'react';
import { CampaignContext } from '../../../context/CampaignContext';
import './campaign-items-table.css';
import MultiSelect from '../multi-select/MultiSelect';
import Checkbox from '../check-box/Checkbox';

const CampaignItemsTable = (props) => {
  const {
    budgetType,
    budget,
    startDate,
    endDate,
    isContinuous,
    targets,
    removeFromTargets,
    ads,
    updateTargetProperty,
    resetDates,
    setResetBudget,
    setResetDates,
    removeAllAdsFromTargets,
    assignAllAdsToTargets,
    calculateAssignedBudget,
  } = useContext(CampaignContext);
  const [showCreativeMenu, setShowCreativeMenu] = useState(false);
  const formatDayMonth = (value) => {
    if (value < 10) {
      return '0' + value;
    }
    return value;
  };

  const formatDate = (value) => {
    return (
      value.getFullYear() +
      '-' +
      formatDayMonth(value.getMonth() + 1) +
      '-' +
      formatDayMonth(value.getDate())
    );
  };

  const options = [];
  const updateTargetAdsFunctions = {};
  for (const [adIndex, ad] of ads.entries()) {
    //console.log(ad);
    options.push({
      value: ads[adIndex]['id'],
      label: ads[adIndex]['name'] + ' - ' + ads[adIndex].landingPage,
    });
  }

  const getPlaceURL = (placeId) => {
    const google = window.google;
    var service = new google.maps.places.PlacesService(
      document.createElement('div')
    );
    service.getDetails(
      {
        placeId: placeId,
        fields: ['website'],
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (
            place.website !== undefined &&
            place.website !== null &&
            place.website !== ''
          ) {
            window.open(place.website);
          }
        }
      }
    );
    return false;
  };

  /*defaultValue={(target['ad'] !== undefined) ? target['ad'] : ''}
                                onChange={(e) => updateTargetProperty(index, 'ad', e.target.value)}*/

  return (
    <div className="campaign-items-table-container">
      <table className="default-table">
        <thead>
          <tr>
            <th className="select">Select</th>
            <th className="target">Target</th>
            <th className="assign">
              Assign Uploaded Ad(s)
              <div
                className="ellipsis-menu"
                onClick={() => setShowCreativeMenu(!showCreativeMenu)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M256 224C238.3 224 224 238.3 224 256c0 17.67 14.33 32 32 32s32-14.33 32-32C288 238.3 273.7 224 256 224zM96 224C78.33 224 64 238.3 64 256c0 17.67 14.33 32 32 32s32-14.33 32-32C128 238.3 113.7 224 96 224zM416 224c-17.67 0-32 14.33-32 32c0 17.67 14.33 32 32 32s32-14.33 32-32C448 238.3 433.7 224 416 224z" />
                </svg>
              </div>
              {showCreativeMenu ? (
                <div className="column-menu-content">
                  <a
                    href="#!"
                    onClick={() => {
                      assignAllAdsToTargets(options);
                      setShowCreativeMenu(false);
                      return false;
                    }}
                  >
                    <div className="column-menu-content-item">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                        >
                          <path d="M254.4 404.7l-44.85 44.87c-40.57 40.54-106.6 40.54-147.1 0c-40.54-40.56-40.54-106.6 0-147.1l128-128c40.57-40.54 106.6-40.54 147.1 0c36.41 36.43 40.57 94.88 9.689 135.1c-5.314 7.064-3.893 17.1 3.172 22.4c7.049 5.312 17.1 3.906 22.4-3.174c40.41-53.73 34.99-130.2-12.63-177.8c-53.04-53.04-139.3-53.01-192.4 0L39.77 279.8c-53.03 53.04-53.03 139.3 0 192.4c26.52 26.52 61.46 39.67 96.3 39.67c34.84 0 69.57-13.17 96.09-39.67l44.85-44.87c6.252-6.252 6.252-16.38 0-22.63C270.8 398.5 260.6 398.5 254.4 404.7zM600.2 39.77c-53.04-53.04-139.3-53.01-192.4 0l-44.85 44.87c-6.252 6.25-6.252 16.38 0 22.63c6.25 6.25 16.38 6.25 22.63 0l44.85-44.87c40.57-40.54 106.6-40.54 147.1 0c40.54 40.56 40.54 106.6 0 147.1l-128 128c-40.57 40.54-106.6 40.54-147.1 0C266 301.1 261.9 242.7 292.8 201.6c5.314-7.064 3.893-17.1-3.172-22.4C282.5 173.9 272.5 175.3 267.2 182.4C226.8 236.1 232.2 312.6 279.8 360.2c26.52 26.52 61.36 39.78 96.19 39.78s69.67-13.27 96.19-39.78l128-128C653.3 179.1 653.3 92.82 600.2 39.77z" />
                        </svg>
                      </div>
                      <div>Assign All Ads to All Targets</div>
                    </div>
                  </a>
                  <a
                    href="#!"
                    onClick={() => {
                      removeAllAdsFromTargets();
                      setShowCreativeMenu(false);
                      return false;
                    }}
                  >
                    <div className="column-menu-content-item">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path d="M480 256c0 123.5-100.4 223.9-223.9 223.9c-69.41 0-133.9-31.3-176.7-86.05c-5.438-6.938-4.203-17 2.75-22.44c6.984-5.531 17.03-4.25 22.47 2.75C141.3 421.1 196.5 448 256 448c105.9 0 192-86.13 192-192s-86.13-192-192-192C187.1 64 124.5 100.7 90.15 160H176C184.8 160 192 167.2 192 176S184.8 192 176 192h-128C39.16 192 32 184.8 32 176v-128C32 39.16 39.16 32 48 32S64 39.16 64 48v93.56C104.4 73.87 176.6 32.11 256.1 32.11C379.6 32.11 480 132.5 480 256z" />
                        </svg>
                      </div>
                      <div>Reset - Unassign All Ads</div>
                    </div>
                  </a>
                </div>
              ) : (
                ''
              )}
            </th>
            <th className="budget">Budget</th>
            <th className="start-date">Start date</th>
            <th className="end-date">Finish date</th>
            <th className="action"></th>
          </tr>
        </thead>
        <tbody>
          {targets.map((target, index) => {
            if (
              (props.tableType === 'business-search' &&
                target['place_id'] !== undefined) ||
              (props.tableType === 'keyword-search' &&
                target['url'] !== undefined)
            ) {
              updateTargetAdsFunctions[index] = function (options, actionMeta) {
                updateTargetProperty(index, 'ads', options);
              };
              return (
                <tr key={index}>
                  <td className="select">
                    {/* <button
                                style={{backgroundColor: "transparent"}}
                                onClick={() => removeFromTargets((target["place_id"] !== undefined) ? 'business-search' : 'keyword-search', target)}> */}
                    <Checkbox
                      checked={true}
                      onClick={() =>
                        removeFromTargets(
                          target['place_id'] !== undefined
                            ? 'business-search'
                            : 'keyword-search',
                          target
                        )
                      }
                    />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path
                                        d="M380.2 453.7c5.703 6.75 4.859 16.84-1.891 22.56C375.3 478.7 371.7 480 368 480c-4.547 0-9.063-1.938-12.23-5.657L192 280.8l-163.8 193.6C25.05 478.1 20.53 480 15.98 480c-3.641 0-7.313-1.25-10.31-3.781c-6.75-5.719-7.594-15.81-1.891-22.56l167.2-197.7L3.781 58.32c-5.703-6.75-4.859-16.84 1.891-22.56c6.75-5.688 16.83-4.813 22.55 1.875L192 231.2l163.8-193.6c5.703-6.688 15.8-7.563 22.55-1.875c6.75 5.719 7.594 15.81 1.891 22.56l-167.2 197.7L380.2 453.7z"/>
                                </svg> */}
                    {/* </button> */}
                  </td>
                  <td className="target">
                    {target['place_id'] !== undefined ? (
                      <a
                        href="#!"
                        onClick={() => getPlaceURL(target['place_id'])}
                      >
                        {target['name']}
                      </a>
                    ) : (
                      <a href={target['url']} target="_blank">
                        {target['title']}
                      </a>
                    )}
                  </td>
                  <td className="assign">
                    <MultiSelect
                      placeholder="+ Click to Select Ads"
                      options={options}
                      value={target['ads'] !== undefined ? target['ads'] : []}
                      onChange={updateTargetAdsFunctions[index]}
                    ></MultiSelect>
                  </td>
                  <td className="budget">
                    $
                    <input
                      type="number"
                      value={
                        target['budget'] === undefined ||
                        isNaN(target['budget'])
                          ? (budget / targets.length).toFixed(2)
                          : target['budget']
                      }
                      onChange={(e) => {
                        setResetBudget(0);
                        updateTargetProperty(index, 'budget', e.target.value);
                        calculateAssignedBudget(index, e.target.value);
                      }}
                      className={
                        target['budget'] !== undefined &&
                        (target['budget'] === '' ||
                          parseInt(target['budget']) === 0)
                          ? 'small-input alert'
                          : 'small-input'
                      }
                    />
                    {budgetType === undefined || budgetType === 'day'
                      ? '/day'
                      : ''}
                  </td>
                  <td className="start-date">
                    <input
                      type="date"
                      defaultValue={
                        resetDates === 1 || target['startDate'] === undefined
                          ? formatDate(startDate)
                          : formatDate(new Date(target['startDate']))
                      }
                      value={
                        resetDates === 1 || target['startDate'] === undefined
                          ? formatDate(startDate)
                          : formatDate(new Date(target['startDate']))
                      }
                      onChange={(e) => {
                        setResetDates(0);
                        updateTargetProperty(
                          index,
                          'startDate',
                          e.target.value
                        );
                      }}
                      min={formatDate(new Date())}
                      className="medium-input"
                    />
                  </td>
                  <td className="end-date">
                    {((resetDates === 1 ||
                      target['isContinuous'] === undefined) &&
                      !isContinuous) ||
                    (target['isContinuous'] !== undefined &&
                      !target['isContinuous']) ? (
                      <input
                        type="date"
                        defaultValue={
                          resetDates === 1 || target['endDate'] === undefined
                            ? formatDate(endDate)
                            : formatDate(new Date(target['endDate']))
                        }
                        value={
                          resetDates === 1 || target['endDate'] === undefined
                            ? formatDate(endDate)
                            : formatDate(new Date(target['endDate']))
                        }
                        onChange={(e) => {
                          setResetDates(0);
                          updateTargetProperty(
                            index,
                            'endDate',
                            e.target.value
                          );
                        }}
                        min={
                          target['startDate'] !== undefined
                            ? formatDate(new Date(target['startDate']))
                            : formatDate(startDate)
                        }
                        className="medium-input"
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className="action">
                    <div>
                      <Checkbox
                        onChange={(e) => {
                          setResetDates(0);
                          updateTargetProperty(
                            index,
                            'isContinuous',
                            e.target.checked
                          );
                        }}
                        checked={
                          resetDates === 1 ||
                          target['isContinuous'] === undefined
                            ? isContinuous
                            : target['isContinuous']
                        }
                        defaultChecked={
                          resetDates === 1 ||
                          target['isContinuous'] === undefined
                            ? isContinuous
                            : target['isContinuous']
                        }
                      />
                      {/* <input type="checkbox"
                                    onChange={(e) => {
                                        setResetDates(0);
                                        updateTargetProperty(index, 'isContinuous', e.target.checked)
                                        }}
                                        checked={(resetDates === 1 || target['isContinuous'] === undefined) ? isContinuous : target['isContinuous']}
                                    defaultChecked={(resetDates === 1 || target['isContinuous'] === undefined) ? isContinuous : target['isContinuous']}/>  */}
                      Continuous Campaign
                    </div>
                  </td>
                </tr>
              );
            }
            return <></>;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CampaignItemsTable;
