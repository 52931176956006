import React from 'react';

function Delete() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4674 2.32552H14.1996H11.4203V0H4.57989V2.32552H1.80063H0.532715V3.7165H1.9057L2.83301 16H13.1673L14.0946 3.7165H15.4675V2.32552H15.4674ZM5.97086 1.39097H10.0294V2.32552H5.97086V1.39097ZM11.8774 14.609H4.12291L3.30057 3.7165H4.57989H11.4203H12.6996L11.8774 14.609Z"
        fill="#131B23"
      />
      <path
        d="M8.55664 6.0918H7.44385V12.2336H8.55664V6.0918Z"
        fill="#131B23"
      />
      <path
        d="M6.42381 6.0918H5.31104V12.2336H6.42381V6.0918Z"
        fill="#131B23"
      />
      <path
        d="M10.6894 6.0918H9.57666V12.2336H10.6894V6.0918Z"
        fill="#131B23"
      />
    </svg>
  );
}

export default Delete;
