import React, { useEffect, useState } from 'react';
import './account-dashboard.css';
import freeEmailDomains from '../../data/FreeEmailDomains';
//import { Auth } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import GetActiveSubscription from '../../api/services/stripe/subscription/get-active-subscription/GetActiveSubscription';
import UpdateSubscriptionPrice from '../../api/services/stripe/subscription/update-subscription/UpdateSubscriptionPrice';
import stripeProducts from '../../data/StripeProducts';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { updateUserAttribute } from 'aws-amplify/auth';

const AccountDashboard = () => {
  let stripeCustomerIdStoredStr =
    process.env.REACT_APP_BUILD_ENV === 'development'
      ? 'custom:StripeCustomerId'
      : 'custom:LiveStripeCustomerId';

  const [fieldAlert, setFieldAlert] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSubscriptionChange, setLoadingSubscriptionChange] =
    useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [subscription, setSubscription] = useState({});
  const [stripeProductId, setStripeProductId] = useState('');

  const emailChangeHandler = (e) => {
    setSuccessMessage('');
    setErrorAlert('');
    setFieldAlert('');
    setEmail(e.target.value);
  };

  const firstNameChangeHandler = (e) => {
    setSuccessMessage('');
    setErrorAlert('');
    setFieldAlert('');
    setFirstName(e.target.value);
  };

  const lastNameChangeHandler = (e) => {
    setSuccessMessage('');
    setErrorAlert('');
    setFieldAlert('');
    setLastName(e.target.value);
  };

  const companyNameChangeHandler = (e) => {
    setSuccessMessage('');
    setErrorAlert('');
    setFieldAlert('');
    setCompanyName(e.target.value);
  };

  const passwordChangeHandler = (e) => {
    setSuccessMessage('');
    setErrorAlert('');
    setFieldAlert('');
    setPassword(e.target.value);
  };

  const isCorporateEmail = (email) => {
    let domainPieces = email.split('@');
    let domain = domainPieces[1];
    let freeEmailDomainsList = freeEmailDomains();
    if (freeEmailDomainsList.includes(domain)) {
      return false;
    }
    return true;
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const changeSubscriptionHandler = async () => {
    setLoadingSubscriptionChange(true);
    let price = stripeProducts()[stripeProductId]['priceId'];
    let updateSubscriptionPriceResponse = await UpdateSubscriptionPrice(
      subscription['id'],
      price
    );
    if (!updateSubscriptionPriceResponse['status']) {
      console.log(updateSubscriptionPriceResponse['error']);
    }
    setLoadingSubscriptionChange(false);
  };

  var moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const saveProfile = async () => {
    setLoading(true);
    setSuccessMessage('');
    if (email === '') {
      setErrorAlert('Please enter a valid email');
      setFieldAlert('email');
      setLoading(false);
      return;
    }
    if (!validateEmail(email)) {
      setErrorAlert('Please enter a valid email');
      setFieldAlert('email');
      setLoading(false);
      return;
    }
    if (!isCorporateEmail(email)) {
      setErrorAlert('Please enter a valid company email');
      setFieldAlert('email');
      setLoading(false);
      return;
    }
    if (firstName === '') {
      setErrorAlert('Please enter a valid First Name');
      setFieldAlert('firstName');
      setLoading(false);
      return;
    }
    if (lastName === '') {
      setErrorAlert('Please enter a valid Last Name');
      setFieldAlert('firstName');
      setLoading(false);
      return;
    }
    if (companyName === '') {
      setErrorAlert('Please enter a Company Name');
      setFieldAlert('companyName');
      setLoading(false);
      return;
    }
    let user = await getCurrentUser();
    async function handleUpdateUserAttribute(attributeKey, value) {
      try {
        const output = await updateUserAttribute({
          userAttribute: {
            attributeKey,
            value,
          },
        });
        handleUpdateUserAttributeNextSteps(output);
      } catch (error) {
        console.log(error);
      }
    }
    function handleUpdateUserAttributeNextSteps(output) {
      const { nextStep } = output;

      switch (nextStep.updateAttributeStep) {
        case 'CONFIRM_ATTRIBUTE_WITH_CODE':
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          console.log(
            `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
          );
          // Collect the confirmation code from the user and pass to confirmUserAttribute.
          break;
        case 'DONE':
          console.log(`attribute was successfully updated.`);
          break;
      }
    }
    //let user = await getCurrentUser();
    // try {
    //     await updateUserAttribute(user, {
    //         'custom:Company': companyName,
    //         'email': email,
    //         'given_name': firstName,
    //         'family_name': lastName
    //     });
    //     setSuccessMessage('Your account has been updated successfully');
    //     setLoading(false);
    // } catch (error) {
    //     setErrorAlert(error.toString());
    //     setLoading(false);
    // }
  };

  useEffect(() => {
    (async () => {
      let currentUserInfo = await fetchUserAttributes();
      if (currentUserInfo['attributes'] !== undefined) {
        if (currentUserInfo['attributes']['given_name'] !== undefined) {
          setFirstName(currentUserInfo['attributes']['given_name']);
        }
        if (currentUserInfo['attributes']['family_name'] !== undefined) {
          setLastName(currentUserInfo['attributes']['family_name']);
        }
        if (currentUserInfo['attributes']['custom:Company'] !== undefined) {
          setCompanyName(currentUserInfo['attributes']['custom:Company']);
        }
        if (currentUserInfo['attributes']['email'] !== undefined) {
          setEmail(currentUserInfo['attributes']['email']);
        }
        if (
          currentUserInfo['attributes'][stripeCustomerIdStoredStr] !==
            undefined &&
          currentUserInfo['attributes'][stripeCustomerIdStoredStr] !== ''
        ) {
          const activeSubscriptionResponse = await GetActiveSubscription(
            currentUserInfo['attributes'][stripeCustomerIdStoredStr]
          );
          if (!activeSubscriptionResponse.status) {
            console.log(activeSubscriptionResponse.error);
          }
          console.log(activeSubscriptionResponse);
          if (activeSubscriptionResponse['info']['plan'] === undefined) {
            return;
          }
          setSubscription(activeSubscriptionResponse['info']);
          setStripeProductId(
            activeSubscriptionResponse['info']['stripeProductId']
          );
        }
      }
    })();
  }, []);

  return (
    <div className="account-dashboard">
      <div className="section-title">Account Settings</div>
      <div className="section-content">
        <h4>Account Information</h4>
        <p>Your personal info and option to manage payment. </p>

        <div className="account-info-section">
          <div className="profile-picture-section">
            <h6>Profile Picture</h6>
            <div className="profile-picture-container">
              {firstName.substring(0, 1)}
              {lastName.substring(0, 1)}
            </div>
          </div>
          <div className="profile-info-section">
            {errorAlert !== '' ? (
              <div className="error-alert">{errorAlert}</div>
            ) : (
              ''
            )}
            {successMessage !== '' ? (
              <div className="success-message">{successMessage}</div>
            ) : (
              ''
            )}
            <div className="form-container">
              <div className="field">
                <label>Company Email</label>
                <input
                  name="email"
                  type="email"
                  placeholder="Company Email"
                  value={email}
                  className={fieldAlert === 'email' ? 'auth-alert' : ''}
                  onChange={(e) => emailChangeHandler(e)}
                />
              </div>
              <div className="field">
                <label>First Name</label>
                <input
                  name="first-name"
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  className={fieldAlert === 'firstName' ? 'auth-alert' : ''}
                  onChange={(e) => firstNameChangeHandler(e)}
                />
              </div>
              <div className="field">
                <label>Last Name</label>
                <input
                  name="last-name"
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  className={fieldAlert === 'lastName' ? 'auth-alert' : ''}
                  onChange={(e) => lastNameChangeHandler(e)}
                />
              </div>
              <div className="field">
                <label>Company Name</label>
                <input
                  name="company-name"
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  className={fieldAlert === 'companyName' ? 'auth-alert' : ''}
                  onChange={(e) => companyNameChangeHandler(e)}
                />
              </div>
              {/*<div className="field">
                                <label>Password</label>
                                <input name="password" type="password" placeholder="Password"
                                       className={(fieldAlert === 'password') ? ("auth-alert") : ""}
                                       onChange={(e) => passwordChangeHandler(e)}/>
                            </div>*/}
              <div className="field">
                {loading ? (
                  <div className="loader">Loading...</div>
                ) : (
                  <button onClick={async () => await saveProfile()}>
                    Save Changes
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {subscription !== undefined &&
        subscription['planInfo'] !== undefined &&
        subscription['planInfo']['price'] !== undefined ? (
          <div className="subscription-info">
            <hr />
            <div className="subscription-info-title">Current Subscription:</div>

            <div className="subscription-info-content">
              <div>
                <select
                  name="subscription"
                  value={stripeProductId}
                  onChange={(e) => setStripeProductId(e.target.value)}
                  placeholder="Please select a budget"
                >
                  {stripeProducts().map((product, key) => {
                    return (
                      <option key={key} value={key}>
                        {moneyFormatter.format(product.price)}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                {loadingSubscriptionChange ? (
                  <div className="loader">Loading...</div>
                ) : (
                  <button onClick={(e) => changeSubscriptionHandler()}>
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default AccountDashboard;
