import axios from 'axios';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import getCatalyticsApiUrl from '../../../data/Urls';

export async function getReportData() {
  let advertiser_id = await getAdvertiserId();
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  console.log(lastDay);

  if (advertiser_id !== undefined) {
    let res = await axios
      .get(getCatalyticsApiUrl() + 'reports/', {
        params: {
          start_date: firstDay.toLocaleDateString('en-CA'),
          end_date: lastDay.toLocaleDateString('en-CA'),
          report_type: 'network_analytics',
          advertiser_id: advertiser_id,
        },
      })
      .then((response) => {
        return JSON.parse(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
      });

    return res;
  } else return {};
}

export async function getLineItems() {
  let advertiser_id = await getAdvertiserId();
  if (advertiser_id !== undefined) {
    let res = await axios
      .get(getCatalyticsApiUrl() + 'line-items/', {
        params: {
          advertiser_id: advertiser_id,
        },
      })
      .then((response) => {
        return JSON.parse(response.data.body);
      })
      .catch(function (error) {
        console.log(error);
      });
    return res;
  } else return {};
}

export async function updateLineItem(object_id, payload) {
  const { accessToken, idToken } = await getSessionToken();
  let data = [
    {
      action_name: 'line item update',
      request: {
        advertiser_id: await getAdvertiserId(),
        service: 'line-item',
        object_id: object_id,
        action: 'put',
      },
      payload: payload,
    },
  ];
  return await axios.post(`${getCatalyticsApiUrl()}interface`, data, {
    headers: {
      Authorization: `Basic ${idToken}`,
      'Content-Type': 'application/json',
    },
  });
}

async function getSessionToken() {
  try {
    const { tokens: session } = await fetchAuthSession();
    return session;
  } catch (error) {
    return null;
  }
}

async function getAdvertiserId() {
  try {
    const attributes = await fetchUserAttributes();
    return attributes['custom:AdvertiserId'];
  } catch (error) {
    return null;
  }
}
