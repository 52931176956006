import React, { useEffect, useContext, useState } from 'react';
import './header.css';
import HelpIcon from '../../assets/icons/Help';
import NotificationIcon from '../../assets/icons/Notification';
import UserIcon from '../../assets/icons/User';
//import { Auth } from 'aws-amplify/auth';
import { UserContext } from '../../context/UserContext';
//import chevronDown from "../../assets/icons/chevron_down.svg";

const Header = () => {
  const [name, setName] = useState('');
  //const { userInfo } = useContext(UserContext);
  const {
    userInfo,
    updateUserInfo,
    authTokens,
    currentSession,
    handleFetchUserAttributes,
  } = useContext(UserContext);
  //console.log('Header 15 - userInfo', userInfo);
  // useEffect(() => {
  //     let apiUserAttributes = handleFetchUserAttributes();
  //     console.log('Header 18 - apiUserAttributes', apiUserAttributes);
  //     // updateUserInfo({
  //     //     apiUserAttributes
  //     // });
  // });
  //let apiUserAttributes = handleFetchUserAttributes();
  //console.log('Header 18 - apiUserAttributes', apiUserAttributes);
  //console.log('Header 22 - userInfo', userInfo);
  //const given_name = userInfo.options.userAttributes ? userInfo.options.userAttributes.given_name : 'Guest';
  return (
    <header>
      <div className="menu-section gap">
        <div className="icon-button">
          <NotificationIcon />
        </div>
        <div className="icon-button">
          <a href="https://docs.catalytics.io/" target="_blank">
            <HelpIcon />
          </a>
        </div>
      </div>
      <div className="divider" />
      <div className="menu-section">
        <UserIcon />
        <div className="user-menu" style={{ fontSize: 12, marginLeft: 8 }}>
          {name}
          {/* <img src={chevronDown} alt="Show Profile Info" />*/}
          <div className="user-menu-options-container">
            {/* <div className="user-menu-option">Option 1</div>
            <div className="user-menu-option">Option 2</div>
            <div className="user-menu-option">Option 3</div> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
