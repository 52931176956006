import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ReactComponent as MagnifyingGlass } from '../../../assets/images/magnifying-glass.svg';
import { defaultHeaders as headers } from './DefaultHeaders';
import { getRowStyle } from './shared/GridControls';
import CustomDatePicker from './shared/CustomDatePicker';
import StatusToggle from './shared/StatusToggle';
import DailyBudget from './shared/DailyBudget';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ColumnFilter from '../Filters/GroupFilter';
import SegmentsFilter from '../Filters/SegmentsFilter';
import './control-room.css';
//import { getLineItems, getReportData } from './api';
import SummaryGrid from './SummaryGrid';
import GetLineItems from '../../../api/services/line-item/get-line-items/GetLineItems';
import GetInsertionOrders from '../../../api/services/insertion-order/get-insertion-orders/GetInsertionOrders';
import GetReport from '../../../api/services/report/get-report/GetReport';
//import { Auth } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import GetActiveSubscription from '../../../api/services/stripe/subscription/get-active-subscription/GetActiveSubscription';
import GetInvoice from '../../../api/services/stripe/invoice/get-invoice/GetInvoice';
import CreateAdvertiser from '../../../api/services/advertiser/create-advertiser/CreateAdvertiser';
import { consoleSandbox } from '@sentry/utils';
//import { object } from 'prop-types';
import DateRangeSelector from './shared/DateRangeSelector/DateRangeSelector';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { updateUserAttribute } from 'aws-amplify/auth';

const MainGrid = () => {
  let stripeCustomerIdStoredStr =
    process.env.REACT_APP_BUILD_ENV === 'development'
      ? 'custom:StripeCustomerId'
      : 'custom:LiveStripeCustomerId';
  const [tableData, setTableData] = useState();
  const [reportData, setReportData] = useState();
  const [lineItemData, setLineItemData] = useState();
  const [editedCampaigns, setEditedCampaigns] = useState({});
  const [gridApi, setGridApi] = useState();
  const [activeSubscriptionAmount, setActiveSubscriptionAmount] = useState(0);
  const [activeSubscriptionPrice, setActiveSubscriptionPrice] = useState(0);
  const [insertionOrders, setInsertionOrders] = useState([]);
  const [activeDailyBudgets, setActiveDailyBudgets] = useState([]);
  const mainGrid = useRef();

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  useEffect(() => {
    const buildTable = async () => {
      let currentUserInfo = await fetchUserAttributes();
      let advertiserId = '';
      if (
        currentUserInfo['attributes'] === undefined ||
        currentUserInfo['attributes']['custom:AdvertiserId'] === undefined ||
        currentUserInfo['attributes']['custom:AdvertiserId'] === ''
      ) {
        const advertiserResponse = await CreateAdvertiser();
        if (!advertiserResponse.status) {
          console.log(advertiserResponse.error);
          /*TODO: handle this error on the UX*/
          return;
        }
        advertiserId = advertiserResponse.info['id'];
        let user = await getCurrentUser();
        await updateUserAttribute(user, {
          'custom:AdvertiserId': advertiserId.toString(),
        });
      } else {
        advertiserId = currentUserInfo['attributes']['custom:AdvertiserId'];
      }
      updateTableData(advertiserId);
      let foundSubscription = false;
      let subscriptionId = '';
      let obtainedActiveSubscriptionAmount = 0;
      let invoiceId = '';
      if (
        currentUserInfo['attributes'][stripeCustomerIdStoredStr] !==
          undefined &&
        currentUserInfo['attributes'][stripeCustomerIdStoredStr] !== ''
      ) {
        const activeSubscriptionResponse = await GetActiveSubscription(
          currentUserInfo['attributes'][stripeCustomerIdStoredStr]
        );
        if (!activeSubscriptionResponse.status) {
          console.log(activeSubscriptionResponse.error);
        }
        if (
          activeSubscriptionResponse['info'] !== undefined &&
          activeSubscriptionResponse['info']['plan'] !== undefined
        ) {
          setActiveSubscriptionPrice(
            activeSubscriptionResponse['info']['planInfo']['price']
          );
          foundSubscription = true;
          subscriptionId = activeSubscriptionResponse['info']['id'];
          let latestInvoice =
            activeSubscriptionResponse['info']['latest_invoice'];
          const invoiceResponse = await GetInvoice(latestInvoice);
          if (invoiceResponse.status) {
            obtainedActiveSubscriptionAmount =
              invoiceResponse.info['amount_due'] / 100;
            setActiveSubscriptionAmount(obtainedActiveSubscriptionAmount);
            invoiceId = invoiceResponse.info['id'];
          }
        }
      }
      const insertionOrdersResponse = await GetInsertionOrders(advertiserId);
      if (!insertionOrdersResponse.status) {
        console.log(insertionOrdersResponse.error);
      }
      if (
        insertionOrdersResponse.info['insertion-orders'] !== null &&
        insertionOrdersResponse.info['insertion-orders'] !== undefined &&
        insertionOrdersResponse.info['insertion-orders'].length > 0
      ) {
        let foundInsertionOrders = [];
        for (let insertionOrder of insertionOrdersResponse.info[
          'insertion-orders'
        ]) {
          if (
            insertionOrder['line_items'] !== undefined &&
            insertionOrder['line_items'] !== null &&
            insertionOrder['line_items'].length > 0
          ) {
            foundInsertionOrders.push(insertionOrder);
          }
        }
        foundInsertionOrders.sort(dynamicSort('name'));
        setInsertionOrders(foundInsertionOrders);
      }
    };
    buildTable();
  }, []);

  function updateTableData(advertiserId) {
    Promise.all([GetReport(advertiserId), GetLineItems(advertiserId)]).then(
      ([reportResponse, lineItemsResponse]) => {
        if (!lineItemsResponse.status) {
          //console.log(lineItemsResponse.error);
          setTableData([]);
          return;
        }
        if (lineItemsResponse.info.length === 0) {
          setTableData([]);
          return;
        }
        if (!reportResponse.status) {
          //console.log(reportResponse.error);
          setTableData([]);
          return;
        }
        setReportData(reportResponse.info);
        setLineItemData(lineItemsResponse.info);

        let dataObj = createDataObj(
          reportResponse.info,
          lineItemsResponse.info
        );
        // console.log("dataObj: "+JSON.stringify(dataObj))
        setTableData(dataObj);
      }
    );
  }

  function createDataObj(reportInfo, lineItemsInfo) {
    console.log('reportInfo: ' + JSON.stringify(reportInfo));
    //console.log("lineItemsInfo: "+JSON.stringify(lineItemsInfo))
    let r = reportInfo;
    let lineItems = lineItemsInfo;
    return lineItems.reduce((p, c) => {
      let budgets = getLineItemInfo(c);

      let idString = c.id.toString();
      let data = budgets.map((b) => {
        console.log('clicks: ' + parseInt(r[idString]?.clicks ?? 0));
        return {
          campaign: c.name,
          //business_article: c.name,
          //target: c.name,
          status: c.state,
          impressions: parseInt(r[idString]?.imps ?? 0),
          clicks: parseInt(r[idString]?.clicks ?? 0),

          ctr:
            parseInt(r[idString]?.clicks ?? 0) /
            parseInt(r[idString]?.imps ?? 0),
          engaged_visits: parseInt(r[idString]?.clicks ?? 0),
          conversions: parseInt(r[idString]?.total_convs ?? 0),
          //spend: parseInt(r[idString]?.total_revenue ?? 0),
          spend: parseFloat(r[idString]?.revenue ?? 0).toFixed(2),
          daily_budget: b.daily_budget,
          //flight_start: b.flightStart,
          //flight_end: b.flightEnd,
        };
      });
      return [...p, ...data];
    }, []);
  }

  function getLineItemInfo(lineItem) {
    let orders = lineItem.insertion_orders;
    if (orders) {
      return orders.reduce((p, c) => {
        let budgetArray = c.budget_intervals.map((b, index) => {
          //let flightStart = new Date(b.start_date);
          //let flightEnd = new Date(b.end_date);
          let daily_budget = 0;
          try {
            daily_budget = lineItem.budget_intervals[index].daily_budget;
            setActiveDailyBudgets([...activeDailyBudgets, daily_budget]);
          } catch {
            console.log(
              'no daily budget found on li: ' + JSON.stringify(lineItem.id)
            );
            daily_budget = 0;
          }
          let id = c.id;
          return {
            id,
            //flightStart,
            //flightEnd,
            daily_budget: daily_budget,
            activeDailyBudgets,
          };
        });
        console.log('budgetArray:' + [...p, ...budgetArray]);

        return [...p, budgetArray[0]];
      }, []);
    } else return [];
  }
  function updateCellValue(e) {
    let editedField = e.colDef.field;
    let data = e.node.data;
    let tempEdited = { ...editedCampaigns };

    if (data.campaign in tempEdited) {
      tempEdited[data.campaign].newValue = {
        ...data,
        [editedField]: e.newValue,
      };
    } else {
      tempEdited[data.campaign] = {
        originalValue: { ...data, [editedField]: e.oldValue },
        newValue: { ...data, [editedField]: e.newValue },
      };
    }
    setEditedCampaigns(tempEdited);
  }

  function onFilterTextBoxChanged(textString) {
    if (mainGrid) {
      mainGrid.current.api.setQuickFilter(textString);
    }
  }

  function initiateGrid({ api }) {
    setGridApi(api);
    api.sizeColumnsToFit();
  }

  async function filterByStatus() {
    let statusFilter = gridApi.getFilterInstance('status');
    statusFilter.setModel({
      filterType: 'text',
      type: 'equals',
      filter: 'active',
    });
    gridApi.onFilterChanged();
  }

  const [summaryData, setSummaryData] = useState([]);

  function updateSummaryData() {
    let data = [];
    gridApi.forEachNodeAfterFilter((rowNode, i) => data.push(rowNode.data));
    if (data.length === 0) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
    setSummaryData(data.filter((d) => d.flight_start > new Date()));
  }

  const statusHeader = {
    field: 'status',
    headerName: 'Status',
    cellRenderer: 'StatusToggle',
    cellRendererParams: { updateTable: updateTableData },
    cellStyle: { textAlign: 'center' },
    filter: 'agTextColumnFilter',
    //filterable: true,
    filterParams: {
      buttons: ['clear'],
    },
  };

  return (
    <div className="grid-container">
      <div className="grid-header">
        <div className="search-bar">
          <input
            placeholder="Search"
            onChange={(e) => onFilterTextBoxChanged(e.target.value)}
          />
          <MagnifyingGlass />
        </div>
        <div className="filter-container">
          <SegmentsFilter />
          <ColumnFilter headers={headers} gridRef={mainGrid} />
          <DateRangeSelector />
        </div>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          ref={mainGrid}
          rowData={tableData}
          columnDefs={[statusHeader, ...headers]}
          maintainColumnOrder={true}
          getRowStyle={getRowStyle}
          onGridReady={initiateGrid}
          onFirstDataRendered={(e) => filterByStatus(e)}
          onCellValueChanged={(e) => updateCellValue(e)}
          onFilterChanged={updateSummaryData}
          frameworkComponents={{
            CustomDatePicker,
            StatusToggle,
            DailyBudget,
          }}
        />
      </div>
      {tableData ? (
        <SummaryGrid
          editedCampaigns={editedCampaigns}
          upcomingFlights={summaryData}
          lineItems={lineItemData}
          reportData={reportData}
          updateTableData={updateTableData}
          insertionOrders={insertionOrders}
          activeSubscriptionAmount={activeSubscriptionAmount}
          activeSubscriptionPrice={activeSubscriptionPrice}
          sumActiveDailyBudgets={activeDailyBudgets}
        />
      ) : null}
    </div>
  );
};

export default MainGrid;
