import React, { useContext, useState } from 'react';
import UploadFiles from './chatgpt-example-catalytics-user-upload';
import UserUpload from '../../api/services/user_upload/UserUpload'
import './fileUpload.css';
import FileUploadTable from './FileUploadTable';
//import JSZip from 'jszip';
import { handleExtractDimensions } from 'components/util/extractDimensions/extractDimensions';
import { UserContext } from 'context/UserContext';
import { extractUserInfo } from 'components/util/dataFormat/dataformat';

const initialFileData = {
  name: [],
  landingPages: [],
  adSizes: [],
};
const FileUploadUI = () => {
  const [uploadFile, setUploadFile] = useState([]);
  const { userInfo } = useContext(UserContext);
  const userData=extractUserInfo(userInfo)
 

  const [fileData, setFileData] = useState({
    name: [],
    landingPages: [],
    adSizes: [],
  });
  const [fileChangeIndex, setFileChangeIndex] = useState(null);
  const handleFileChange = async (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const newFiles = [];

      for (let i = 0; i < uploadFile.length; i++) {
        newFiles.push(uploadFile[i]);
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'application/x-zip-compressed') {
          const dimensions = await handleExtractDimensions(files[i]);
          if (!dimensions?.width && !dimensions?.height) {
            continue;
          }
        }
        if (fileChangeIndex) {
          newFiles[fileChangeIndex] = files[i];
        } else {
          newFiles.push(files[i]);
        }
      }
      if (newFiles.length > 21) {
        alert('You can select a maximum of 21 files.');
        return;
      }
      setUploadFile(newFiles);
      setFileChangeIndex(null);
    } else {
      alert('No files selected.');
      setFileChangeIndex(null);
    }
  };

  const handleBrowseClick = (inputId) => {
    console.log(inputId)
    document.getElementById(inputId).click();

  };

  const handleClick = async () => {
    //const response = await UploadFiles(uploadFile, userData);
    const response = await UserUpload(uploadFile, userData);
    if (response) {
      setFileData(initialFileData)
      setUploadFile([])
    }
  };
  const handleSizeClick = (index) => {
    const ext = fileData.name[index].split('.').pop();
    if (ext === 'png' || ext === 'jpg' || ext === 'jpeg')
      document.getElementById('fileInput').click();
    else if (ext === 'zip') document.getElementById('fileInput-zip').click();
    else if (ext === 'mp4') document.getElementById('fileInput-vid').click();
    else document.getElementById('fileInput').click();
    setFileChangeIndex(index);
  };
  return (
    
      <div className="container-wrapper">
        <div>
          <div className="main-wrapper">
            <h5>Upload Ads & Assign Landing Pages</h5>
            <div className="cards-group">
              <div className="card-main">
                <div className="card-block">
                  <div className="cards-wrap-content">
                    <h5>Upload Static Ad(s)</h5>
                    <p className="file-extension">(.jpg or .png)</p>
                    <p className="intro-add">instructions & examples</p>
                  </div>
                    <input
                      type="file"
                      id="fileInput"
                      multiple
                      accept=".jpg,.png"
                      onChange={handleFileChange}
                      className='brows-btn'
                    />
                  </div>
              </div>

              <div className="card-main">
                <div className="card-block">
                  <div className="cards-wrap-content">
                    <h5>Upload HTML5/JS Ad(s)</h5>
                    <p className="file-extension">(.zip)</p>
                    <p className="intro-add">instructions & examples</p>
                  </div>
                  <div className="browse-button">
                    <input
                      type="file"
                      id="fileInput-zip"
                      multiple
                      accept=".zip"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>

              <div className="card-main">
                <div className="card-block">
                  <div className="cards-wrap-content">
                    <h5>Upload Video Ad(s)</h5>
                    <p className="file-extension">(.mp4)</p>
                    <p className="intro-add">instructions & examples</p>
                  </div>
                    <input
                      type="file"
                      id="fileInput-vid"
                      multiple
                      accept=".mp4"
                      onChange={handleFileChange}
                      //style={{ display: 'none' }}
                    />
                </div>
              </div>
            </div>
          </div>
          <FileUploadTable
            uploadFile={uploadFile}
            handleuploadFile={(file) => setUploadFile(file)}
            setFileData={(data) => setFileData(data)}
            fileData={fileData}
            handleSizeClick={handleSizeClick}
          />
        </div>
        <div className="confirm-upload-btn">
          <button className="button secondary" onClick={handleClick}>
            confirm & upload
          </button>
        </div>
      </div>
    
  );
};

export default FileUploadUI;
