import React from 'react';
import '../create-campaign/create-campaign.css';
import './control-room.css';
import ControlRoomGrid from './Tables/ControlRoomGrid';

const ControlRoom = () => {
  let title = 'manage campaigns';
  let subTitle =
    'View and edit your campaigns including budget, flight dates,status and more. Need help managing your campaigns? Contact us';

  return (
    <div className="step-box">
      <div className="create-campaign-title">
        <h1>Control Room</h1>
      </div>
      <div className="business-search">
        <div className="business-search-container">
          <div className="business-search-form">
            <div className="step-description">
              <h2>{title}</h2>
              <p>{subTitle}</p>
            </div>
            <ControlRoomGrid />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlRoom;
