import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../../data/Urls';

let paymentIntentResponse = {
  status: false,
  error: '',
  info: {},
};

const CreatePaymentIntent = async (amount) => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  await axios
    .post(getCatalyticsApiUrl() + 'stripe-payment-intent', {
      params: {
        amount: amount,
        env:
          process.env.REACT_APP_BUILD_ENV === 'development'
            ? 'development'
            : '',
      },
      headers: {
        Authorization: `Basic ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(async (response) => {
      if (response.data === undefined || response.data.body === undefined) {
        paymentIntentResponse.error = 'Empty response from POST payment intent';
        ApiException(
          paymentIntentResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          response
        );
      }
      let body = JSON.parse(response['data']['body']);
      if (
        body['paymentIntent'] === undefined ||
        body['paymentIntent']['client_secret'] === undefined
      ) {
        paymentIntentResponse.error =
          'Payment Intent info not returned from POST payment intent';
        ApiException(
          paymentIntentResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          response
        );
      }
      let clientSecret = body['paymentIntent']['client_secret'];
      paymentIntentResponse.status = true;
      paymentIntentResponse.info = clientSecret;
    })
    .catch(function (error) {
      paymentIntentResponse.error = error;
      ApiException(
        paymentIntentResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        {},
        {}
      );
    });
  return paymentIntentResponse;
};

export default CreatePaymentIntent;
