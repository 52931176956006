import axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../data/Urls';

const GetInsertionOrders = async (advertiserId) => {
  let insertionOrdersResponse = {
    status: false,
    error: '',
    info: {},
  };
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  const request = { advertiserId: advertiserId };
  await axios
    .get(getCatalyticsApiUrl() + 'insertion-orders', {
      params: {
        advertiserId: advertiserId,
      },
      headers: {
        Authorization: `Basic ${accessToken}`,
      },
    })
    .then(async (response) => {
      if (response['data'] === null) {
        insertionOrdersResponse.error =
          'Empty response from GET insertion orders';
        ApiException(
          insertionOrdersResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          response
        );
        return;
      }
      let body = JSON.parse(response['data']['body']);
      if (
        body['response']['status'] === undefined ||
        body['response']['status'] !== 'OK'
      ) {
        insertionOrdersResponse.error =
          'Invalid response from GET insertion orders';
        ApiException(
          insertionOrdersResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          body
        );
        return;
      }
      if (body['Error'] === undefined) {
        insertionOrdersResponse.status = true;
        insertionOrdersResponse.info = body['response'];
      }
      //insertionOrdersResponse = await CreateInsertionOrders(advertiserId, name);
    })
    .catch(function (error) {
      insertionOrdersResponse.error = error;
      ApiException(
        insertionOrdersResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        request,
        {}
      );
    });
  return insertionOrdersResponse;
};

export default GetInsertionOrders;
