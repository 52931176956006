import axios from 'axios';
import { useContext } from 'react';

import { UserContext } from 'context/UserContext';
import { ApiException } from 'api/services/logging/api/api-exception/apiException';
import getCatalyticsApiUrl from 'data/Urls';

let createCustomerResponse = {
  status: false,
  error: '',
  info: {},
};

const CreateCustomer = async () => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  await axios
    .post(
      getCatalyticsApiUrl() + 'stripe-customer',
      {
        email: currentUserInfo['attributes']['email'],
        env:
          process.env.REACT_APP_BUILD_ENV === 'development'
            ? 'development'
            : '',
      },
      {
        headers: {
          Authorization: `Basic ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then(async (response) => {
      if (response.data === undefined || response.data.body === undefined) {
        createCustomerResponse.error =
          'Empty response from POST stripe customer';
        ApiException(
          createCustomerResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          response
        );
      }
      let body = JSON.parse(response['data']['body']);
      if (body['customerId'] === undefined) {
        createCustomerResponse.error =
          'customer id not returned from POST stripe customer';
        ApiException(
          createCustomerResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          response
        );
      }
      let stripeCustomerId = body['customerId'].toString();
      createCustomerResponse.status = true;
      createCustomerResponse.info = stripeCustomerId;
    })
    .catch(function (error) {
      createCustomerResponse.error = error;
      ApiException(
        createCustomerResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        {},
        {}
      );
    });
  return createCustomerResponse;
};

export default CreateCustomer;
