import React from 'react';
import './not-found.css';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h3>Page not found</h3>
    </div>
  );
};

export default NotFound;
