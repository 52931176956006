import React, { useState, useEffect, useRef } from 'react';
import Delete from '../../../../../assets/icons/Delete';
import { SketchPicker } from 'react-color';
import { toPng } from 'html-to-image';
import * as Draggable from 'react-draggable';
import './needs-help-ads.css';
import adSizes from '../../../../../data/AdSizes';
import { downloadData, uploadData } from '@aws-amplify/storage';

const NeedsHelpAds = (props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [selectedBrandColor, setSelectedBrandColor] = useState({
    r: 200,
    g: 200,
    b: '200',
    a: '1',
  });
  const [adWidth, setAdWidth] = useState(300);
  const [adHeight, setAdHeight] = useState(250);
  const [creativeHeadline, setCreativeHeadline] = useState('');
  const [headlinePosition, setHeadlinePosition] = useState('left');
  const [displayHeadlineColorPicker, setDisplayHeadlineColorPicker] =
    useState(false);
  const [headlineColor, setHeadlineColor] = useState({
    r: 250,
    g: 250,
    b: 250,
    a: '1',
  });
  const [headlineFontSize, setHeadlineFontSize] = useState(14);
  const [creativeCopy, setCreativeCopy] = useState('');
  const [copyPosition, setCopyPosition] = useState('left');
  const [displayCopyColorPicker, setDisplayCopyColorPicker] = useState(false);
  const [copyColor, setCopyColor] = useState({
    r: 250,
    g: 250,
    b: 250,
    a: '1',
  });
  const [copyFontSize, setCopyFontSize] = useState(12);
  const [logoFile, setLogoFile] = useState(null);
  const [logoWidth, setLogoWidth] = useState(200);
  const [bgImage, setBgImage] = useState(null);
  const [windowHeight, setWindowHeight] = useState(0);
  const ref = useRef(null);
  const exportRef = useRef();
  const logoInputRef = useRef();
  const bgImageInputRef = useRef();
  const [creativeCta, setCreativeCta] = useState('');
  const [ctaPosition, setCtaPosition] = useState('left');
  const [displayCtaColorPicker, setDisplayCtaColorPicker] = useState(false);
  const [ctaColor, setCtaColor] = useState({ r: 0, g: 0, b: 0, a: '1' });
  const [displayCtaBgColorPicker, setDisplayCtaBgColorPicker] = useState(false);
  const [ctaBgColor, setCtaBgColor] = useState({
    r: 250,
    g: 250,
    b: 250,
    a: '1',
  });
  const [ctaFontSize, setCtaFontSize] = useState(14);

  useEffect(() => {
    setWindowHeight(ref.current.clientHeight + 200);
  }, []);

  const creativeAdSizeHandler = (e) => {
    setAdWidth(adSizes()[e.target.value].width);
    setAdHeight(adSizes()[e.target.value].height);
  };

  const colorPickerClickHandler = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const colorPickerCloseHandler = () => {
    setDisplayColorPicker(false);
  };

  const colorPickerChangeHandler = (color) => {
    setSelectedBrandColor(color.rgb);
  };

  const creativeHeadlineHandler = (e) => {
    setCreativeHeadline(e.target.value);
  };

  const headlineColorPickerClickHandler = () => {
    setDisplayHeadlineColorPicker(!displayHeadlineColorPicker);
  };

  const headlineColorPickerCloseHandler = () => {
    setDisplayHeadlineColorPicker(false);
  };

  const headlineColorPickerChangeHandler = (color) => {
    setHeadlineColor(color.rgb);
  };

  const creativeHeadlinePositionHandler = (e) => {
    setHeadlinePosition(e.target.value);
  };

  const creativeHeadlineFontSizeHandler = (e) => {
    setHeadlineFontSize(e.target.value);
  };

  const creativeCopyHandler = (e) => {
    setCreativeCopy(e.target.value);
  };

  const copyColorPickerClickHandler = () => {
    setDisplayCopyColorPicker(!displayCopyColorPicker);
  };

  const copyColorPickerCloseHandler = () => {
    setDisplayCopyColorPicker(false);
  };

  const copyColorPickerChangeHandler = (color) => {
    setCopyColor(color.rgb);
  };

  const creativeCopyPositionHandler = (e) => {
    setCopyPosition(e.target.value);
  };

  const creativeCopyFontSizeHandler = (e) => {
    setCopyFontSize(e.target.value);
  };

  const creativeCtaHandler = (e) => {
    setCreativeCta(e.target.value);
  };

  const ctaBgColorPickerClickHandler = () => {
    setDisplayCtaBgColorPicker(!displayCtaBgColorPicker);
  };

  const ctaBgColorPickerCloseHandler = () => {
    setDisplayCtaBgColorPicker(false);
  };

  const ctaBgColorPickerChangeHandler = (color) => {
    setCtaBgColor(color.rgb);
  };

  const ctaColorPickerClickHandler = () => {
    setDisplayCtaColorPicker(!displayCtaColorPicker);
  };

  const ctaColorPickerCloseHandler = () => {
    setDisplayCtaColorPicker(false);
  };

  const ctaColorPickerChangeHandler = (color) => {
    setCtaColor(color.rgb);
  };

  const creativeCtaPositionHandler = (e) => {
    setCtaPosition(e.target.value);
  };

  const creativeCtaFontSizeHandler = (e) => {
    setCtaFontSize(e.target.value);
  };

  const logoWidthChangeHandler = (e) => {
    setLogoWidth(e.target.value);
  };

  const blobToBase64 = (blob) => {};

  const logoUploadHandler = async (e) => {
    const file = e.target.files[0];
    let fileNamePieces = file.name.split('.');
    let fileExtension = fileNamePieces[fileNamePieces.length - 1];
    await uploadData(file.name, file, {
      level: 'private',
      contentType: file.type,
    })
      .result.then(async (result) => {
        let fileToSave = file;
        fileToSave['key'] = result['key'];
        fileToSave['eTag'] = result['eTag'];
        fileToSave['fileInfo'] = await downloadData(fileToSave['key'], {
          accessLevel: 'private',
        });
        const reader = new FileReader();
        reader.readAsDataURL(fileToSave['fileInfo']['Body']);
        reader.onloadend = function () {
          var base64data = reader.result;
          setLogoFile(base64data);
        };
      })
      .catch((err) => console.log(err));
  };

  const bgImageUploadHandler = async (e) => {
    const file = e.target.files[0];
    let fileNamePieces = file.name.split('.');
    let fileExtension = fileNamePieces[fileNamePieces.length - 1];
    await uploadData(file.name, file, {
      accessLevel: 'private',
      contentType: file.type,
    })
      .result.then(async (result) => {
        let fileToSave = file;
        fileToSave['key'] = result['key'];
        fileToSave['eTag'] = result['eTag'];
        fileToSave['fileInfo'] = await downloadData(fileToSave['key'], {
          accessLevel: 'private',
        });
        const reader = new FileReader();
        reader.readAsDataURL(fileToSave['fileInfo']['Body']);
        reader.onloadend = function () {
          var base64data = reader.result;
          setBgImage(base64data);
        };
      })
      .catch((err) => console.log(err));
  };

  const exportAsImage = () => {
    /*const style = {
     transform: 'scale('+scale+')',
     'transform-origin': 'top left',
     width: node.offsetWidth + "px",
     height: node.offsetHeight + "px",
 }

 const param = {
    height: node.offsetHeight * scale,
    width: node.offsetWidth * scale,
    style
 }*/
    toPng(exportRef.current, {
      cacheBust: true,
      width: adWidth,
      height: adHeight,
      canvasWidth: adWidth,
      canvasHeight: adHeight,
      pixelRatio: 1,
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'banner.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeLogo = () => {
    setLogoFile(null);
    logoInputRef.current.value = '';
  };

  const removeBgImage = () => {
    setBgImage(null);
    bgImageInputRef.current.value = '';
  };

  return (
    <div className="needs-help-ads-container" ref={ref}>
      <button
        className="needsHelp"
        data-tip="We can help you assemble your ad"
        onClick={() => {
          props.needsHelpHandler(false);
        }}
      >
        Back to Upload Ads
      </button>
      <h3>Need Help creating ads?</h3>
      <div className="needs-help-explanation">
        <div>
          <p className="note">
            Use this tool to create your ad and press{' '}
            <button
              onClick={() => exportAsImage(/*exportRef.current, "banner"*/)}
            >
              Download
            </button>{' '}
            when ready
          </p>
        </div>
        <div className="needs-help-content-container">
          <div className="needs-help-column">
            <div class="scrollable" style={{ height: `${windowHeight}px` }}>
              {/*<ol>
                            <li>1. Logo Upload (high resolution .png or .svg with transparent background)</li>
                            <li>2. Brand Colors</li>
                            <li>3. Preferred Background Image (optional)</li>
                            <li>4. Headlines / Ad Copy</li>
                        </ol>*/}
              <div className="two-columns">
                <div>
                  <label className="field-title">Ad Size</label>
                  <div className="field">
                    <select onChange={(e) => creativeAdSizeHandler(e)}>
                      {adSizes().map((adSize, adSizeIndex) => {
                        return (
                          <option key={adSizeIndex} value={adSizeIndex}>
                            {adSize['width']}x{adSize['height']}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  <label className="field-title">Background color</label>
                  <div className="field">
                    <div className="color-picker-container">
                      <div
                        className="swatch"
                        onClick={() => colorPickerClickHandler()}
                      >
                        <div
                          className="color-box"
                          style={{
                            backgroundColor: `rgba(${selectedBrandColor['r']}, ${selectedBrandColor['g']}, ${selectedBrandColor['b']}, ${selectedBrandColor['a']})`,
                          }}
                        />
                      </div>
                      {displayColorPicker ? (
                        <div className="pop-over bottom">
                          <div
                            className="cover"
                            onClick={() => colorPickerCloseHandler()}
                          />
                          <SketchPicker
                            color={selectedBrandColor}
                            onChange={(color) => {
                              colorPickerChangeHandler(color);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <label className="field-title">Headline</label>
              <div className="field">
                <label>Text</label>
                <textarea
                  onChange={(e) => creativeHeadlineHandler(e)}
                ></textarea>
              </div>
              <div className="two-columns">
                {/*<div className="field">
                                    <label>Position</label>
                                    <select onChange={(e) => creativeHeadlinePositionHandler(e)}>
                                        <option value="left">left</option>
                                        <option value="center">center</option>
                                        <option value="right">right</option>
                                    </select>
                                </div>*/}
                <div className="field">
                  <label>Text color</label>
                  <div className="color-picker-container">
                    <div
                      className="swatch"
                      onClick={() => headlineColorPickerClickHandler()}
                    >
                      <div
                        className="color-box"
                        style={{
                          backgroundColor: `rgba(${headlineColor['r']}, ${headlineColor['g']}, ${headlineColor['b']}, ${headlineColor['a']})`,
                        }}
                      />
                    </div>
                    {displayHeadlineColorPicker ? (
                      <div className="pop-over">
                        <div
                          className="cover"
                          onClick={() => headlineColorPickerCloseHandler()}
                        />
                        <SketchPicker
                          color={headlineColor}
                          onChange={(color) => {
                            headlineColorPickerChangeHandler(color);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="field">
                  <label>Font Size (px)</label>
                  <select
                    value={headlineFontSize}
                    onChange={(e) => creativeHeadlineFontSizeHandler(e)}
                  >
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                  </select>
                </div>
              </div>
              <hr />
              <label className="field-title">Ad Copy</label>
              <div className="field">
                <label>Text</label>
                <textarea onChange={(e) => creativeCopyHandler(e)}></textarea>
              </div>
              <div className="two-columns">
                {/*<div className="field">
                                    <label>Position</label>
                                    <select onChange={(e) => creativeCopyPositionHandler(e)}>
                                        <option value="left">left</option>
                                        <option value="center">center</option>
                                        <option value="right">right</option>
                                    </select>
                                </div>*/}
                <div className="field">
                  <label>Text color</label>

                  <div className="color-picker-container">
                    <div
                      className="swatch"
                      onClick={() => copyColorPickerClickHandler()}
                    >
                      <div
                        className="color-box"
                        style={{
                          backgroundColor: `rgba(${copyColor['r']}, ${copyColor['g']}, ${copyColor['b']}, ${copyColor['a']})`,
                        }}
                      />
                    </div>
                    {displayCopyColorPicker ? (
                      <div className="pop-over">
                        <div
                          className="cover"
                          onClick={() => copyColorPickerCloseHandler()}
                        />
                        <SketchPicker
                          color={copyColor}
                          onChange={(color) => {
                            copyColorPickerChangeHandler(color);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="field">
                  <label>Font Size (px)</label>
                  <select
                    value={copyFontSize}
                    onChange={(e) => creativeCopyFontSizeHandler(e)}
                  >
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                  </select>
                </div>
              </div>
              <hr />
              <div className="field">
                <label className="field-title">Logo (optional)</label>
                <div className="two-columns">
                  <div className="field">
                    <label>File</label>
                    <input
                      type="file"
                      name="logo"
                      accept=".png,.jpg,.svg"
                      ref={logoInputRef}
                      onChange={logoUploadHandler}
                    />
                    {logoFile !== null ? (
                      <button
                        className="icon right-margin"
                        onClick={() => removeLogo()}
                      >
                        <Delete />
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    <label>Width</label>
                    <input
                      type="number"
                      name="logo-width"
                      value={logoWidth}
                      onChange={logoWidthChangeHandler}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="field">
                <label className="field-title">
                  Background image (optional)
                </label>
                <input
                  type="file"
                  name="bg-image"
                  accept=".png,.jpg,.svg"
                  ref={bgImageInputRef}
                  onChange={bgImageUploadHandler}
                />
                {bgImage !== null ? (
                  <button className="icon" onClick={() => removeBgImage()}>
                    <Delete />
                  </button>
                ) : (
                  ''
                )}
              </div>
              <hr />
              <label className="field-title">
                Call to Action / Button (optional)
              </label>
              <div className="field">
                <label>Text</label>
                <input onChange={(e) => creativeCtaHandler(e)}></input>
              </div>
              <div className="three-columns">
                {/*<div className="field">
                                    <label>Position</label>
                                    <select onChange={(e) => creativeCtaPositionHandler(e)}>
                                        <option value="left">left</option>
                                        <option value="center">center</option>
                                        <option value="right">right</option>
                                    </select>
                                </div>*/}
                <div className="field">
                  <label>Text color</label>
                  <div className="color-picker-container">
                    <div
                      className="swatch"
                      onClick={() => ctaColorPickerClickHandler()}
                    >
                      <div
                        className="color-box"
                        style={{
                          backgroundColor: `rgba(${ctaColor['r']}, ${ctaColor['g']}, ${ctaColor['b']}, ${ctaColor['a']})`,
                        }}
                      />
                    </div>
                    {displayCtaColorPicker ? (
                      <div className="pop-over">
                        <div
                          className="cover"
                          onClick={() => ctaColorPickerCloseHandler()}
                        />
                        <SketchPicker
                          color={ctaColor}
                          onChange={(color) => {
                            ctaColorPickerChangeHandler(color);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="field">
                  <label>Background color</label>
                  <div className="color-picker-container">
                    <div
                      className="swatch"
                      onClick={() => ctaBgColorPickerClickHandler()}
                    >
                      <div
                        className="color-box"
                        style={{
                          backgroundColor: `rgba(${ctaBgColor['r']}, ${ctaBgColor['g']}, ${ctaBgColor['b']}, ${ctaBgColor['a']})`,
                        }}
                      />
                    </div>
                    {displayCtaBgColorPicker ? (
                      <div className="pop-over">
                        <div
                          className="cover"
                          onClick={() => ctaBgColorPickerCloseHandler()}
                        />
                        <SketchPicker
                          color={ctaBgColor}
                          onChange={(color) => {
                            ctaBgColorPickerChangeHandler(color);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="field">
                  <label>Font Size (px)</label>
                  <select
                    value={ctaFontSize}
                    onChange={(e) => creativeCtaFontSizeHandler(e)}
                  >
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                  </select>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div className="needs-help-column second-column">
            <div className="preview-container">
              <div className="field">
                <label>Preview (click any element to drag and drop)</label>
                <div
                  ref={exportRef}
                  className="creative-preview"
                  style={{
                    backgroundColor: `rgba(${selectedBrandColor['r']}, ${selectedBrandColor['g']}, ${selectedBrandColor['b']}, ${selectedBrandColor['a']})`,
                    height: `${adHeight}px`,
                    width: `${adWidth}px`,
                    backgroundImage:
                      bgImage !== null ? `url(${bgImage})` : `none`,
                  }}
                >
                  {logoFile !== null ? (
                    <Draggable bounds="parent">
                      <img
                        crossOrigin="anonymous"
                        className="grabbable creative-preview-logo"
                        src={logoFile}
                        style={{
                          width: `${logoWidth}px`,
                        }}
                      />
                    </Draggable>
                  ) : (
                    ''
                  )}
                  <Draggable bounds="parent">
                    <h4
                      className="grabbable"
                      style={{
                        textAlign: `${headlinePosition}`,
                        fontSize: `${headlineFontSize}px`,
                        color: `rgba(${headlineColor['r']}, ${headlineColor['g']}, ${headlineColor['b']}, ${headlineColor['a']})`,
                      }}
                    >
                      {creativeHeadline}
                    </h4>
                  </Draggable>
                  <Draggable bounds="parent">
                    <p
                      className="grabbable"
                      style={{
                        textAlign: `${copyPosition}`,
                        fontSize: `${copyFontSize}px`,
                        color: `rgba(${copyColor['r']}, ${copyColor['g']}, ${copyColor['b']}, ${copyColor['a']})`,
                      }}
                    >
                      {creativeCopy}
                    </p>
                  </Draggable>
                  {creativeCta !== '' ? (
                    <Draggable bounds="parent">
                      <button
                        className="grabbable"
                        style={{
                          marginLeft:
                            ctaPosition === 'center' || ctaPosition === 'right'
                              ? `auto`
                              : `0px`,
                          marginRight:
                            ctaPosition === 'center' || ctaPosition === 'left'
                              ? `auto`
                              : `0px`,
                          fontSize: `${ctaFontSize}px`,
                          color: `rgba(${ctaColor['r']}, ${ctaColor['g']}, ${ctaColor['b']}, ${ctaColor['a']})`,
                          backgroundColor: `rgba(${ctaBgColor['r']}, ${ctaBgColor['g']}, ${ctaBgColor['b']}, ${ctaBgColor['a']})`,
                        }}
                      >
                        {creativeCta}
                      </button>
                    </Draggable>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedsHelpAds;
