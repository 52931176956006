import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../../data/Urls';
import stripeProducts from '../../../../../data/StripeProducts';

let subscriptionResponse = {
  status: false,
  error: '',
  info: {},
};

const CreateSubscription = async (priceSubscriptionId, stripeCustomerId) => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  await axios
    .post(
      getCatalyticsApiUrl() + 'stripe-subscription',
      {
        customer: stripeCustomerId,
        /*"quantity": parseInt(thisTotalToPay * 100),*/
        subscription: stripeProducts()[priceSubscriptionId]['priceId'],
        quantity: 1,
        env:
          process.env.REACT_APP_BUILD_ENV === 'development'
            ? 'development'
            : '',
      },
      {
        headers: {
          Authorization: `Basic ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then(async (response) => {
      if (response.data === undefined || response.data.body === undefined) {
        subscriptionResponse.error = 'Empty response from POST subscription';
        ApiException(
          subscriptionResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          response
        );
        return;
      }
      let body = JSON.parse(response['data']['body']);
      if (
        body['subscriptionId'] === undefined ||
        body['clientSecret'] === undefined
      ) {
        subscriptionResponse.error =
          'Subscription info not returned from POST subscription';
        ApiException(
          subscriptionResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          response
        );
        return;
      }
      subscriptionResponse.status = true;
      subscriptionResponse.info = body;
    })
    .catch(function (error) {
      subscriptionResponse.error = error;
      ApiException(
        subscriptionResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        {},
        {}
      );
    });
  return subscriptionResponse;
};

export default CreateSubscription;
