import React from 'react';

function CalendarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0431 1.69411V0.75293H9.91371V1.69411H6.08629V0.75293H4.95688V1.69411H0V15.247H16V1.69411H11.0431ZM4.95688 2.82352V3.76469H6.08629V2.82352H9.91375V3.57646H11.0432V2.82352H14.8706V4.70587H1.12941V2.82352H4.95688ZM1.12941 14.1176V5.83528H14.8706V14.1176H1.12941Z"
        fill="#29EF9C"
      />
      <path
        d="M5.55295 10.7294H3.67059V12.6117H5.55295V10.7294Z"
        fill="#29EF9C"
      />
      <path
        d="M8.94119 10.7294H7.05884V12.6117H8.94119V10.7294Z"
        fill="#29EF9C"
      />
      <path
        d="M12.3294 10.7294H10.4471V12.6117H12.3294V10.7294Z"
        fill="#29EF9C"
      />
      <path
        d="M8.94119 7.34119H7.05884V9.22353H8.94119V7.34119Z"
        fill="#29EF9C"
      />
      <path
        d="M12.3294 7.34119H10.4471V9.22353H12.3294V7.34119Z"
        fill="#29EF9C"
      />
      <path
        d="M5.55295 7.34119H3.67059V9.22353H5.55295V7.34119Z"
        fill="#29EF9C"
      />
    </svg>
  );
}

export default CalendarIcon;
