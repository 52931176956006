import Logo from '../../../assets/images/logo-white.svg';
import LogoDark from '../../../assets/images/logo-dark.svg';
//import {AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn, AmplifySignUp} from "@aws-amplify/ui-react";
import React from 'react';
import './auth-index.css';
//import { Amplify } from 'aws-amplify';
//import { getCurrentUser } from 'aws-amplify/auth';
//import { fetchAuthSession } from 'aws-amplify/auth';
////import { Auth } from 'aws-amplify/auth';
//import { Auth } from 'aws-amplify/auth';
//import {AuthState} from "@aws-amplify/ui-components";
import AuthComponents from '../auth-components/AuthComponents';

const AuthIndex = () => {
  return (
    <div className="auth-components-container">
      <div className="login-image">
        {/* <div className = "triangle-overlay"></div> */}
        <div className="logo-container">
          <img src={Logo} className="logo" alt="Catalytics Logo" />
        </div>
      </div>
      <div className="login-container">
        <AuthComponents />
      </div>
      <div id="corner_links">
        <a href="/">Support</a>
        <a href="/contact">Terms of Use</a>
      </div>
    </div>
  );
};

export default AuthIndex;
