import React from 'react';

import { ReactDOM, render } from 'react-dom';
// import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './components/App';
//import * as serviceWorker from './serviceWorker';
import './styles/reset.css';
import './styles/main.css';
import './styles/formats.css';
import './styles/text-tags.css';

Sentry.init({
  dsn: 'https://19983dac2417435aae9ba42b330d8bc9@o1160537.ingest.sentry.io/6245043',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

render(<App />, document.getElementById('root'));

// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<App tab="Create Campaign" />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
