import React from 'react';

function Check() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3334 27.8154L35.1853 10.9615L37.7795 13.5539L18.3334 33L6.66602 21.3327L9.25835 18.7404L18.3334 27.8154Z"
        fill="#131B23"
      />
    </svg>
  );
}

export default Check;
