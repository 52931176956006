import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './steps-bar.css';
import { CampaignContext } from '../../../../context/CampaignContext';

const StepsBar = (props) => {
  const { targets, ads, adsHaveValidLandingPages, campaignIsReady } =
    useContext(CampaignContext);

  let steps = [
    'Select Targets',
    'Choose Ads',
    'Setup Campaign',
    'Create Account',
    'Next Steps',
  ];
  return (
    <div className="steps-bar">
      {steps.map((step, index) => {
        const linkStr = '/create-campaign/' + index;
        var canGoToStep = false;
        switch (index) {
          case 0:
            if (parseInt(props.step) !== 5) {
              canGoToStep = true;
            }
            break;
          case 1:
            if (
              targets !== undefined &&
              targets.length > 0 &&
              parseInt(props.step) !== 5
            ) {
              canGoToStep = true;
            }
            break;
          case 2:
            if (
              targets !== undefined &&
              targets.length > 0 &&
              ads !== undefined &&
              ads.length > 0 &&
              adsHaveValidLandingPages &&
              parseInt(props.step) !== 5
            ) {
              canGoToStep = true;
            }
            break;
          case 3:
            if (campaignIsReady && parseInt(props.step) !== 5) {
              canGoToStep = true;
            }
            break;
          case 4:
            canGoToStep = false;

            break;
          default:
            break;
        }

        const active = index + 1 === parseInt(props.step);
        const visited = index + 1 < props.step;

        return (
          <div key={index} className="step-wrapper">
            {index > 0 && (
              <div
                className={`connector ${active ? 'active' : ''} ${visited ? 'visited' : ''}`}
              />
            )}
            <div
              className={`step-container ${active ? 'active' : ''} ${visited ? 'visited' : ''}`}
            >
              <NavLink
                className={`${active ? 'selected' : ''} ${visited ? 'visited' : ''} ${canGoToStep ? '' : 'disabled'}`}
                to={canGoToStep ? linkStr : ''}
              >
                <pre>
                  {index + 1}. {step}
                </pre>
              </NavLink>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepsBar;
