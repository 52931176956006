import { useState, useEffect } from 'react';
import './fileUpload.css';
import { addMetadataToFile, extractUrlFromFileName, formatDimensions, handleExtractDimensions, handleImageDimensions, handleVideoDimensions } from '../util/extractDimensions/extractDimensions';

const FileUploadTable = ({ uploadFile,handleuploadFile ,setFileData,fileData,handleSizeClick}) => {

  useEffect(() => {
    const fetchAndSetFileData = async () => {
      console.log("call2")
      const extractedFileData = Object.keys(uploadFile).map((key,index) => {
        const {name,url}=extractUrlFromFileName(uploadFile[index])
        console.log(url,"sd")
        const file = uploadFile[key];
        return {
          name: name,
          size: file.size,
          url: url,
          sizeFormatted: `${(file.size / 1024).toFixed(2)} KB`,
          ...file,
        };
      });
      let dimensionsResult = [];
      const dimensionsPromises = extractedFileData.map(async (file,index) => {
        console.log(file)                                                 
        console.log(uploadFile[0])
        if (uploadFile[index].type?.indexOf('image') > -1) {
          const imageDimension = await handleImageDimensions(uploadFile[index]);
          return imageDimension ? `${imageDimension.width} x ${imageDimension.height}` : '--';
        } else if (uploadFile[index].type === 'video/mp4') {
          const videoDimension = await handleVideoDimensions(uploadFile[index]);
          return videoDimension ? `${videoDimension.width} x ${videoDimension.height}` : '--';
        } else if (uploadFile[index].type === 'application/x-zip-compressed') {
          const dimension = await handleExtractDimensions(uploadFile[index]);
          console.log(dimension,"diamenti")
          return (dimension?.width && dimension?.height)
            ? formatDimensions(dimension)
            : '--';
        }
        else{
          console.log("else")
          return '--';
        }
      });            
       dimensionsResult = await Promise.all(dimensionsPromises);
       console.log(dimensionsResult,"diamen")

      setFileData({
        name: extractedFileData.map((file) => file.name),
        landingPages: extractedFileData.map((file) => file.url),
        adSizes: extractedFileData.map((file) => file.sizeFormatted),
        dimensions: dimensionsResult,
      });
    };

    fetchAndSetFileData();
  }, [uploadFile]);

  const handleInputChange = async (value, index, key) => {
    // const updatedData = [...fileData[key]];
    // updatedData[index] = value;
    // setFileData((prevState) => ({
    //   ...prevState,
    //   [key]: updatedData,
    // }));
    
    if(key==="name"){
      const updatedFiles = [...uploadFile]; 
      const fileToUpdate = updatedFiles[index];
      console.log(fileToUpdate,"fileToUpdate")
      const {name,url}=await extractUrlFromFileName(fileToUpdate)
      console.log()
      const values=url?`${value}?url=${encodeURIComponent(url)}`:`${value}`;

      const updatedFile = new File([fileToUpdate], values, {
        type: fileToUpdate.type,    
        lastModified: fileToUpdate.lastModified
      });
      updatedFiles[index] = updatedFile;
      console.log(updatedFiles,"updatedFiles")
      handleuploadFile(updatedFiles)
    }
    if(key==="landingPages"){
      const updatedFiles = [...uploadFile]; 
      const fileToUpdate = updatedFiles[index];
      const {name,url}= await extractUrlFromFileName(fileToUpdate)
      const values=`${name}?url=${encodeURIComponent(value)}`;

      const updatedFile = new File([fileToUpdate], values, {
        type: fileToUpdate.type,    
        lastModified: fileToUpdate.lastModified
      });
      updatedFiles[index] = updatedFile;
      handleuploadFile(updatedFiles)
    }
  };

  return (
    <>
      <div className="Filtypeblock-container">
        <div className="Filtypeblock-action">
          <div className="title-name">File</div>
          {fileData.name.map((file, index) => (
            <input
              key={index}
              type="text"
              className="button-typeimg"
              value={file}
              onChange={(e) => handleInputChange(e.target.value, index, 'name')}
            />
          ))}
        </div>
        <div className="Filtypeblock-action">    
          <div className="title-name">File Size</div>     
          {fileData.adSizes.map((adSize, index) => (   
            <input   
              key={index}  
              type="text"
              className="button-typeimg"  
              value={adSize}  
              onClick={()=>handleSizeClick(index)}
              readOnly
            />  
          ))}
        </div>  
        <div className="Filtypeblock-action"> 
          <div className="title-name">Ad Dimensions</div>
          {fileData?.dimensions?.map((landingPage, index) => ( 
            <input
              key={index}  
              type="text"
              className="button-typeimg"
              value={landingPage}
              readOnly
            />
          ))}
        </div>
        <div className="Filtypeblock-action">
          <div className="title-name">Landing Page</div>
          {fileData.landingPages.map((landingPage, index) => (
            <input
              key={index}
              defaultValue={'https://example.com/landing'}
              placeholder='https://example.com/landing'
              type="text"
              className="button-typeimg"
              value={landingPage}
              onChange={(e) => handleInputChange(e.target.value, index, 'landingPages')}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default FileUploadTable;
