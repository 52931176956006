import React from 'react';
import './checkbox-style.css';

function Checkbox({ label, ...props }) {
  return (
    <label className={`checkbox-container ${label ? '' : 'no-label'}`}>
      {label && label}
      <input type="checkbox" {...props} />
      <span className="checkmark">✔</span>
    </label>
  );
}

export default Checkbox;
