import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../../data/Urls';
import stripeProducts from '../../../../../data/StripeProducts';

let subscriptionsResponse = {
  status: false,
  error: '',
  info: {},
};

const GetActiveSubscription = async (customerId) => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  await axios
    .get(getCatalyticsApiUrl() + 'stripe-subscriptions', {
      params: {
        customerId: customerId,
        env:
          process.env.REACT_APP_BUILD_ENV === 'development'
            ? 'development'
            : '',
      },
      headers: {
        Authorization: `Basic ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(async (responseGet) => {
      if (
        responseGet.data === undefined ||
        responseGet.data.body === undefined
      ) {
        subscriptionsResponse.error = 'Empty response from GET subscription';
        ApiException(
          subscriptionsResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          responseGet
        );
        return;
      }
      let body = JSON.parse(responseGet['data']['body']);
      if (body['subscriptionInfo'] === undefined) {
        subscriptionsResponse.error =
          'Subscriptions info not returned from GET subscription';
        ApiException(
          subscriptionsResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          responseGet
        );
        return;
      }
      if (body['subscriptionInfo']['data'] === undefined) {
        subscriptionsResponse.status = true;
        subscriptionsResponse.info = {};
        return;
      }
      if (body['subscriptionInfo']['data'].length === 0) {
        subscriptionsResponse.status = true;
        subscriptionsResponse.info = {};
        return;
      }
      let lastSubscription = {};
      let lastSubscriptionTime = 0;
      for (let subscription of body['subscriptionInfo']['data']) {
        if (subscription.created > lastSubscriptionTime) {
          lastSubscriptionTime = subscription.created;
          lastSubscription = subscription;
        }
      }
      let stripeProductId = 0;
      for (const priceSubscription of stripeProducts()) {
        if (lastSubscription['plan']['id'] === priceSubscription['priceId']) {
          lastSubscription['planInfo'] = priceSubscription;
          lastSubscription['stripeProductId'] = stripeProductId;
        }
        stripeProductId++;
      }
      subscriptionsResponse.status = true;
      subscriptionsResponse.info = lastSubscription;
    })
    .catch(function (error) {
      subscriptionsResponse.error = error;
      ApiException(
        subscriptionsResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        {},
        {}
      );
    });
  return subscriptionsResponse;
};

export default GetActiveSubscription;
