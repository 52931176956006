import React, { createContext, useState, useEffect, useCallback } from 'react';
import {
  confirmResetPassword,
  confirmSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
  signUp,
} from 'aws-amplify/auth';
import { getCurrentUser, signIn, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
const UserContext = createContext();

const UserProvider = (props) => {
  const [userInfo, setUserInfo] = useState({});

  const listener = useCallback((data) => {
    console.log('usercontext 37 - hub listener:' + data);
  }, []);

  const updateUserInfo = useCallback((newInfo) => {
    setUserInfo((userInfo) => {
      const updatedUserInfo = { ...userInfo, ...newInfo };
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
      return updatedUserInfo;
    });
  }, []);

  const getCurrentUserInfo = useCallback(async () => {
    let updatedUserInfo = null;
    try {
      const { username, userId: id, signInDetails } = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      const { tokens: session } = await fetchAuthSession();

      //console.log(`line 45 - currentSession: ${JSON.stringify(session)}`);

      updatedUserInfo = {
        id,
        username,
        attributes,
        authView: 'authenticated',
        ...session,
      };
      updateUserInfo(updatedUserInfo);

      return {
        id,
        username,
        attributes,
        session,
        authenticationFlowType: signInDetails.authFlowType,
      };
    } catch (error) {
      updateUserInfo({
        id: null,
        username: null,
        attributes: null,
        authView: 'login',
        accessToken: null,
        idToken: null,
      });
    }
  }, [updateUserInfo]);

  const requestSignIn = useCallback(
    // { username, password }
    async (signInInfo) => {
      console.log('requestSignIn');
      try {
        const { isSignedIn, nextStep } = await signIn(signInInfo);
        console.log(isSignedIn, nextStep);
        if (isSignedIn) await getCurrentUserInfo();
        return { isSignedIn, nextStep };
      } catch (error) {
        console.log('error signing in', error);
        throw error;
      }
    },
    [getCurrentUserInfo]
  );

  const requestSignUp = useCallback(
    // { username, password, terms, options: { userAttributes: { given_name, family_name, 'custom:Company', email } } }
    async (signUpInfo) => {
      console.log('requestSignUp');
      try {
        await signUp(signUpInfo);
        await getCurrentUserInfo();
      } catch (error) {
        throw error;
      }
    },
    [getCurrentUserInfo]
  );

  const requestConfirmSignUp = useCallback(
    async (confirmSignUpInfo) => {
      console.log('requestConfirmSignUp');

      try {
        const { nextStep, isSignUpComplete } =
          await confirmSignUp(confirmSignUpInfo);
        console.log('isSignUpComplete', isSignUpComplete);
        console.log('nextStep', nextStep);
        await getCurrentUserInfo();
      } catch (error) {
        throw error;
      }
    },
    [getCurrentUserInfo]
  );

  const requestResetPassword = useCallback(async (resetInfo) => {
    console.log('requestResetPassword');
    try {
      return await resetPassword(resetInfo);
    } catch (error) {
      throw error;
    }
  }, []);

  const requestConfirmResetPassword = useCallback(async (confirmResetInfo) => {
    console.log('requestConfirmResetPassword');
    try {
      return await confirmResetPassword(confirmResetInfo);
    } catch (error) {
      throw error;
    }
  }, []);

  const requestSignOut = useCallback(async () => {
    console.log('requestSignOut');
    try {
      await signOut();
      await getCurrentUserInfo();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }, [getCurrentUserInfo]);

  useEffect(() => {
    (async () => {
      await getCurrentUserInfo();
    })();
    Hub.listen('auth', listener);
  }, [getCurrentUserInfo, listener]);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        requestSignIn,
        requestSignOut,
        requestSignUp,
        requestConfirmSignUp,
        requestResetPassword,
        requestConfirmResetPassword,
        updateUserInfo,
        getCurrentUserInfo,
        getAuthenticatedUser: getCurrentUserInfo,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
