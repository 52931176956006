import axios from 'axios';
//import { Auth } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import { ApiException } from '../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../data/Urls';
import { useState } from 'react';

let advertiserResponse = {
  status: false,
  error: '',
  info: {},
};

const CreateAdvertiser = async () => {
  ////const currentUserInfo = await Auth.currentUserInfo();
  const [accessToken, setAccessToken] = useState('');
  const currentUserInfo = await getCurrentUser();
  const { username, userId, signInDetails } = await getCurrentUser();

  //const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    console.log('accessToken', accessToken);
    setAccessToken(accessToken);
  } catch (err) {
    console.log(err);
  }
  const request = {
    firstName: currentUserInfo['attributes']['given_name'],
    lastName: currentUserInfo['attributes']['family_name'],
    companyName: currentUserInfo['attributes']['custom:Company'],
    emailAddress: currentUserInfo['attributes']['email'],
  };
  await axios
    .post(
      getCatalyticsApiUrl() + 'create-advertiser',
      {
        body: JSON.stringify(request),
      },
      {
        headers: {
          Authorization: `Basic ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    )
    .then(async (responseCreate) => {
      if (
        responseCreate.data === undefined ||
        responseCreate.data.body === undefined
      ) {
        advertiserResponse.error = 'Empty response from POST create advertiser';
        ApiException(
          advertiserResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      let responseBody = JSON.parse(responseCreate.data.body);
      if (
        responseBody['status'] === undefined ||
        responseBody['status'] !== 'OK' ||
        responseBody['id'] === undefined ||
        responseBody['id'] === ''
      ) {
        advertiserResponse.error =
          'Error response from POST create advertiser (false status or missing id)';
        ApiException(
          advertiserResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      advertiserResponse.status = true;
      advertiserResponse.info = responseBody;
    })
    .catch(function (error) {
      advertiserResponse.error = error;
      ApiException(
        advertiserResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        request,
        {}
      );
    });
  return advertiserResponse;
};

export default CreateAdvertiser;
