import React, { useState, useContext } from 'react';
import freeEmailDomains from '../../../data/FreeEmailDomains';
import './auth-components.css';
import { UserContext } from '../../../context/UserContext';
import LogoDark from '../../../assets/images/logo-dark.svg';

const AuthComponents = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [terms, setTerms] = useState(false);

  const [authView, setAuthView] = useState('login');
  const [fieldAlert, setFieldAlert] = useState('');
  const [authErrorAlert, setAuthErrorAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    userInfo,
    requestSignIn,
    requestSignUp,
    requestConfirmSignUp,
    requestResetPassword,
    requestConfirmResetPassword,
  } = useContext(UserContext);
  async function handleSignIn(e) {
    e.preventDefault();
    e.stopPropagation();
    let fieldAlert = '';
    if (username === '') fieldAlert += ' username';
    if (password === '') fieldAlert += ' password';
    setFieldAlert(fieldAlert);
    if (fieldAlert !== '') return;

    setLoading(true);
    console.log('handleSignIn line 70 - userInfo', userInfo);
    try {
      const {
        isSignedIn,
        nextStep: { signInStep },
      } = await requestSignIn({
        username,
        password,
      });
      if (signInStep === 'CONFIRM_SIGN_UP') setAuthView('confirm-signup');
      if (signInStep === 'RESET_PASSWORD') setAuthView('confirm-reset');
      setAuthErrorAlert('');
    } catch (error) {
      setAuthErrorAlert(`<p>Sign In Error: ${error.message}</p>`);
    }
    setLoading(false);
  }

  async function handleSignUp(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log(
      `handleSignUp 65 - values: ${username}, ${password}, ${firstName}, ${lastName}, ${companyName}, ${terms}`
    );
    let fieldAlert = '';
    if (username === '') fieldAlert += ' username';
    if (firstName === '') fieldAlert += ' firstName';
    if (lastName === '') fieldAlert += ' lastName';
    if (companyName === '') fieldAlert += ' companyName';
    if (password === '') fieldAlert += ' password';
    if (userInfo.terms === false) fieldAlert += ' terms';
    setFieldAlert(fieldAlert);
    if (fieldAlert !== '') return;
    setLoading(true);

    try {
      await requestSignUp({
        username: username,
        password: password,
        terms: terms.checked,
        options: {
          userAttributes: {
            given_name: firstName,
            family_name: lastName,
            'custom:Company': companyName,
            email: username,
          },
        },
      });
      setAuthView('confirm-signup');
      setAuthErrorAlert('');
    } catch (error) {
      setAuthErrorAlert(`<p>Sign up error: ${error.message}</p>`);
      console.log('sign up error: ', error);
    }
    setLoading(false);
  }

  async function handleSignUpConfirmation(e) {
    e.preventDefault();
    e.stopPropagation();
    let fieldAlert = '';
    if (username === '') fieldAlert += ' username';
    if (confirmationCode === '') fieldAlert += ' confirmationCode';
    setFieldAlert(fieldAlert);
    if (fieldAlert !== '') return;

    setLoading(true);

    try {
      await requestConfirmSignUp({
        username,
        confirmationCode,
      });
      setAuthErrorAlert('');
      window.location.href = '/create-campaign/0'
    } catch (error) {
      setAuthErrorAlert(
        `<p>There was an problem confirming your account.</p><p>${error.message}</p><p>Please make sure you entered the correct code with no leading or trailing spaces.</p>`
      );
      console.log('error confirming sign up', error);
    }
    setLoading(false);
  }

  async function handleResetPassword(e) {
    e.preventDefault();
    e.stopPropagation();
    let fieldAlert = '';
    if (username === '') fieldAlert += ' username';
    setFieldAlert(fieldAlert);
    if (fieldAlert !== '') return;

    setLoading(true);

    try {
      const { nextStep } = await requestResetPassword({ username });

      switch (nextStep.resetPasswordStep) {
        case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
          const codeDeliveryDetails = nextStep.codeDeliveryDetails;
          console.log('Code delivery details', codeDeliveryDetails);
          setAuthView('confirm-reset');
          setAuthErrorAlert('');
          break;
        case 'DONE':
          console.log('Successfully reset password.');
          break;
        default:
          break;
      }

      setAuthErrorAlert(
        `<p>Your password reset code was sent to ${username}</p>`
      );
    } catch (error) {
      console.log('reset password error: ', error);
      setAuthErrorAlert(`<p>There was an problem resetting your password:</p>
                <p>${error.message}</p>
                <p>Please make sure you entered a valid registered email address.</p>
            `);
    }
    setLoading(false);
  }

  async function handleConfirmResetPassword(e) {
    e.preventDefault();
    e.stopPropagation();
    let fieldAlert = '';
    console.log('BBB', username, confirmationCode, newPassword);
    if (username === '') fieldAlert += ' username';
    if (confirmationCode === '') fieldAlert += ' confirmationCode';
    if (newPassword === '') fieldAlert += ' newPassword';
    setFieldAlert(fieldAlert);
    if (fieldAlert !== '') return;

    setLoading(true);

    alert('AAA');

    try {
      const confirmResetResponse = await requestConfirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      });
      console.log('confirmResetResponse', confirmResetResponse);
      setAuthView('login');
      setAuthErrorAlert(`<p>Your password has been changed:</p>`);
      return;
    } catch (error) {
      console.log(error);
      setAuthErrorAlert(`<p>There was an problem with your confirmation code:</p>
                <p>${error.message}</p>
                <p>Please make sure there are no leading or trailing spaces in your confirmation code.</p>
            `);
    }
    setLoading(false);
  }

  switch (authView) {
    case 'login':
    default:
      return (
        <div className="auth-container">
          <div className="logo-container">
            <img src={LogoDark} className="logo" alt="Catalytics Logo" />
          </div>
          <h3 className="glitch" data-text="Welcome Back">
            Welcome Back
          </h3>
          {authErrorAlert !== '' && (
            <div
              className="auth-error-alert"
              dangerouslySetInnerHTML={{ __html: authErrorAlert }}
            />
          )}
          <form className="login-form">
            <div className="auth-field">
              {/* <label>Email</label> */}
              <input
                type="email"
                name="username"
                id="username"
                placeholder="Registered Email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="auth-field">
              {/* <label>Password</label> */}
              <input
                type="password"
                name="password"
                placeholder="Password"
                id="password"
                className={fieldAlert.includes('password') ? 'auth-alert' : ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="button-container">
              {loading ? (
                <div className="loader">Loading...</div>
              ) : (
                <button type="submit" onClick={handleSignIn}>
                  Sign In
                </button>
              )}
            </div>
          </form>
          <div className="create-account-container">
            <p>
              Need to{' '}
              <a
                href="#!"
                onClick={() => {
                  setAuthView('signup');
                  setAuthErrorAlert('');
                }}
              >
                Sign up?
              </a>
            </p>
            <p>
              <a
                href="#!"
                onClick={() => {
                  setAuthView('request-reset');
                  setAuthErrorAlert('');
                }}
              >
                Reset Password
              </a>
            </p>
          </div>
        </div>
      );
    case 'signup':
      return (
        <div className="auth-container signup-container ">
          <div className="logo-container">
            <img src={LogoDark} className="logo" alt="Catalytics Logo" />
          </div>
          <h3>Account Setup</h3>
          {authErrorAlert !== '' && (
            <div
              className="auth-error-alert"
              dangerouslySetInnerHTML={{ __html: authErrorAlert }}
            />
          )}
          <form className="login-form" onSubmit={handleSignUp}>
            <div className="auth-field">
              <input
                name="username"
                id="username"
                type="email"
                placeholder="Company Email"
                autoComplete="email"
                className={fieldAlert.includes('email') ? 'auth-alert' : ''}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="auth-field">
              <input
                name="firstName"
                id="firstName"
                type="text"
                placeholder="First Name"
                autoComplete="given-name"
                className={fieldAlert.includes('firstName') ? 'auth-alert' : ''}
                value={firstName}
                onChange={(e) => setfirstName(e.target.value)}
              />
            </div>
            <div className="auth-field">
              <input
                name="lastName"
                id="lastName"
                type="text"
                placeholder="Last Name"
                autoComplete="family-name"
                className={fieldAlert.includes('lastName') ? 'auth-alert' : ''}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="auth-field">
              <input
                name="companyName"
                id="companyName"
                type="text"
                placeholder="Company Name"
                autoComplete="organization"
                className={
                  fieldAlert.includes('companyName') ? 'auth-alert' : ''
                }
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="auth-field">
              <input
                name="password"
                id="password"
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                className={fieldAlert.includes('password') ? 'auth-alert' : ''}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="auth-field">
              <input
                name="terms"
                id="terms"
                type="checkbox"
                className="checkbox"
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
              />{' '}
              I agree to the{' '}
              <a
                href="https://catalytics.io/resources/terms-of-use.html"
                target="_blank"
                rel="noreferrer"
              >
                Catalytics Terms of Use
              </a>
            </div>
            {!terms && (
              <div className="accept-terms-alert">
                Please accept the Catalytics Terms of Use
              </div>
            )}
            <div className="button-container">
              {loading ? (
                <div className="loader">Loading...</div>
              ) : (
                <button
                  id="signUpButton"
                  onClick={handleSignUp}
                  disabled={fieldAlert !== ''}
                >
                  Sign Up
                </button>
              )}
            </div>
          </form>
          <div className="create-account-container ">
            <p>
              Have an account?{' '}
              <a
                href="#!"
                onClick={() => {
                  setAuthView('login');
                  setAuthErrorAlert('');
                }}
              >
                Sign in
              </a>
            </p>
          </div>
        </div>
      );
    case 'confirm-signup':
      return (
        <div className="auth-container confirm-signup-container">
          <div className="logo-container">
            <img src={LogoDark} className="logo" alt="Catalytics Logo" />
          </div>
          <h3>Please enter the code we sent to your email</h3>
          {authErrorAlert !== '' && (
            <div
              className="auth-error-alert"
              dangerouslySetInnerHTML={{ __html: authErrorAlert }}
            />
          )}
          <div className="auth-field">
            <input
              name="username"
              id="username"
              type="email"
              placeholder="Company Email"
              autoComplete="email"
              className={fieldAlert.includes('email') ? 'auth-alert' : ''}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="auth-field">
            <label>Code</label>
            <input
              type="text"
              name="confirmationCode"
              id="confirmationCode"
              placeholder="Confirmation Code"
              className={
                fieldAlert.includes('confirmationCode') ? 'auth-alert' : ''
              }
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </div>
          <div className="button-container">
            {loading ? (
              <div className="loader">Loading...</div>
            ) : (
              <button onClick={handleSignUpConfirmation}>Continue</button>
            )}
          </div>
        </div>
      );
    case 'request-reset':
      return (
        <div className="auth-container confirm-signup-container ">
          <div className="logo-container">
            <img src={LogoDark} className="logo" alt="Catalytics Logo" />
          </div>
          <h3>Request Password Reset</h3>
          {authErrorAlert !== '' ? (
            <div
              className="auth-error-alert"
              dangerouslySetInnerHTML={{ __html: authErrorAlert }}
            />
          ) : (
            ''
          )}
          <div className="auth-field">
            <input
              id="username"
              name="username"
              type="email"
              placeholder="Company Email"
              autoComplete="email"
              className={fieldAlert.includes('email') ? 'auth-alert' : ''}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="button-container">
            {loading ? (
              <div className="loader">Loading...</div>
            ) : (
              <button onClick={handleResetPassword}>Continue</button>
            )}
          </div>
          <div className="create-account-container">
            <p>
              Need to{' '}
              <a
                href="#!"
                onClick={() => {
                  setAuthView('signup');
                  setAuthErrorAlert('');
                }}
              >
                Sign up?
              </a>
            </p>
          </div>
        </div>
      );

    case 'confirm-reset':
      return (
        <div className="auth-container confirm-signup-container ">
          <div className="logo-container">
            <img src={LogoDark} className="logo" alt="Catalytics Logo" />
          </div>
          <h3>
            Please enter the code we sent to your email and your new password
          </h3>
          {authErrorAlert !== '' && (
            <div
              className="auth-error-alert"
              dangerouslySetInnerHTML={{ __html: authErrorAlert }}
            />
          )}
          <div className="auth-field">
            <label>Code</label>
            <input
              type="text"
              name="confirmationCode"
              className={
                fieldAlert.includes('confirmationCode') ? 'auth-alert' : ''
              }
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </div>
          <div id="passwordResetErrorMessage">{authErrorAlert}</div>
          <div className="auth-field">
            <label>New Password</label>
            <input
              type="password"
              name="newPassword"
              autoComplete="newPassword"
              value={newPassword}
              className={fieldAlert.includes('newPassword') ? 'auth-alert' : ''}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="button-container">
            {loading ? (
              <div className="loader">Loading...</div>
            ) : (
              <button onClick={handleConfirmResetPassword}>Continue</button>
            )}
          </div>
          <p>
            <a
              href="#!"
              onClick={() => {
                setAuthView('login');
                setAuthErrorAlert('');
              }}
            >
              Return to login
            </a>
          </p>
          <div className="create-account-container">
            <p>
              Need to{' '}
              <a
                href="#!"
                onClick={() => {
                  setAuthView('signup');
                  setAuthErrorAlert('');
                }}
              >
                Sign up?
              </a>
            </p>
            <p>
              <a
                href="#!"
                onClick={() => {
                  setAuthView('request-reset');
                  setAuthErrorAlert('');
                }}
              >
                Reset Password
              </a>
            </p>
          </div>
        </div>
      );
  }

  // return (
  //     <AuthForm
  //         userInfo={{ userInfo }}
  //         fieldAlert={fieldAlert}
  //         handleFieldChange={handleFieldChange}
  //         handleSignUp={handleSignUp}
  //         handleSignIn={handleSignIn}
  //         //handleSignOut= {handleSignOut}
  //         handleSignUpConfirmation={handleSignUpConfirmation}
  //         handleResetPassword={handleResetPassword}
  //         handleConfirmResetPassword={handleConfirmResetPassword}
  //         authErrorAlert={authErrorAlert}
  //         setAuthErrorAlert={setAuthErrorAlert}
  //         loading={loading}
  //         setAuthView={setAuthView}
  //         authView={authView}
  //         terms={userInfo.terms}
  //         setTerms={setTerms}
  //         showAlertTerms={showAlertTerms}
  //         isButtonDisabled={isButtonDisabled}
  //     />
  // );
};

export default AuthComponents;
