import React, { useState } from 'react';
import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';

export default function DailyBudget(props) {
  return (
    <span title="Edit daily budget" style={{ textAlign: 'center' }}>
      {props.valueFormatted}
      <Edit
        height={20}
        width={20}
        style={{
          textAlign: 'center',
          verticalAlign: 'middle',
          paddingLeft: 5,
          paddingBottom: 3,
        }}
      />
    </span>
  );
}
