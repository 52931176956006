import React, { useContext } from 'react';
//import {Auth} from 'aws-amplify';
//import { Auth } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
//import { getCurrentUser } from 'aws-amplify/auth';
//import { fetchAuthSession } from 'aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';
import { NavLink, useLocation } from 'react-router-dom';
import './side-nav.css';
import { CampaignContext } from '../../context/CampaignContext';
import logo from '../../assets/images/logo-white.svg';
import CalendarIcon from '../../assets/icons/Calendar';
import ControlRoomIcon from '../../assets/icons/ControlRoom';
import SettingsIcon from '../../assets/icons/Settings';
import TrackingIcon from '../../assets/icons/tracking_icon.svg';
import LogoutIcon from '../../assets/icons/Logout';
import Delete from '../../assets/icons/Delete';
import { UserContext } from '../../context/UserContext';
import { set } from 'date-fns';

const SideNav = () => {
  const { setAuthTokens, updateUserInfo } = useContext(UserContext);
  const { cleanCampaign, campaigns, activeCampaign, updateCampaign } =
    useContext(CampaignContext);
  const location = useLocation();
  const logout = async function () {
    try {
      await signOut();
      //cleanCampaign();
      //window.location = '/';
      console.log('signed out');
      updateUserInfo({ authView: 'login' });
      localStorage.clear();
      window.location = '/';
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <div className="side-nav">
      <div className="container">
        <div className="logo">
          <img src={logo} alt="Catalytics" />
        </div>
        <div className="menu">
          <ul>
            <li>
              <a
                href="#!"
                className={
                  location.pathname.indexOf('create-campaign') !== -1 &&
                  activeCampaign == ''
                    ? 'active'
                    : ''
                }
                onClick={() => {
                  updateCampaign({ activeCampaign: '' });
                  window.location = '/create-campaign/0';
                }}
              >
                <CalendarIcon />
                <span>Create Campaign</span>
              </a>
              <ul className={campaigns.length > 4 ? 'min-height' : ''}>
                {campaigns.map((campaign, index) => {
                  return (
                    <li key={index}>
                      <a
                        href="#!"
                        className={activeCampaign == campaign ? 'active' : ''}
                        onClick={() => {
                          updateCampaign(
                            {
                              activeCampaign: campaign,
                            },
                            campaign
                          );
                          window.location = '/create-campaign/0';
                        }}
                      >
                        &bull;&nbsp;&nbsp;&nbsp;
                        {campaign}
                      </a>
                      <button
                        onClick={() => {
                          cleanCampaign(campaign);
                          window.location = '/create-campaign/0';
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M380.2 453.7c5.703 6.75 4.859 16.84-1.891 22.56C375.3 478.7 371.7 480 368 480c-4.547 0-9.063-1.938-12.23-5.657L192 280.8l-163.8 193.6C25.05 478.1 20.53 480 15.98 480c-3.641 0-7.313-1.25-10.31-3.781c-6.75-5.719-7.594-15.81-1.891-22.56l167.2-197.7L3.781 58.32c-5.703-6.75-4.859-16.84 1.891-22.56c6.75-5.688 16.83-4.813 22.55 1.875L192 231.2l163.8-193.6c5.703-6.688 15.8-7.563 22.55-1.875c6.75 5.719 7.594 15.81 1.891 22.56l-167.2 197.7L380.2 453.7z" />
                        </svg>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              <NavLink to="/control-room">
                <ControlRoomIcon />
                <span>Control Room</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/account-dashboard">
                <SettingsIcon />
                <span>Manage Account</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/tracking">
                <img
                  src={TrackingIcon}
                  alt="tracking icon"
                  width="16px"
                  height="16px"
                />
                <span>Tracking</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="bottom-links">
          <ul>
            <li>
              <a href="#!" onClick={logout}>
                <LogoutIcon />
                <span>Log out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

//export default withRouter(SideNav);
export default SideNav;
