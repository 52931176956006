import * as Sentry from '@sentry/react';

export const ApiException = (errorMsg, user, request, response) => {
  Sentry.setContext('request_response', {
    request: JSON.stringify(request),
    response: JSON.stringify(response),
  });
  Sentry.captureException(errorMsg, (scope) => {
    scope.setTransactionName('API Error: ' + errorMsg);
    scope.setLevel('error');
    scope.setUser(user);
  });
};
