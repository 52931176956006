import { post } from '@aws-amplify/api';

async function UploadFiles(files, userData) {
  const filesArray = Array.from(files);

  if (filesArray.length < 1) {
    alert('Please select at least one file.');
    return false;
  } else if (filesArray.length > 21) {
    alert('You can select a maximum of 21 files.');
    return false;
  }

  const formData = new FormData();
  filesArray.forEach((file) => {
    formData.append('file', file);
  });
  Object.keys(userData).forEach((key) => {
    formData.append(key, userData[key]);
  });

  try {
    const response = await post('Catalytics_STAGING_API', '/user_upload', {
      body: formData,
      headers: {
        Accept: 'multipart/form-data',
      },
    });

    console.log('Files uploaded successfully:', response);
    alert('Files uploaded successfully!');
    return true;
  } catch (error) {
    console.error('Error uploading files:', error);
    alert('An error occurred while uploading files. Please try again.');
    return false;
  }
}

export default UploadFiles;