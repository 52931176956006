import axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import { useContext } from 'react';
import * as Sentry from '@sentry/react';
import { ApiException } from '../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../data/Urls';

let urlSegmentResponse = {
  status: false,
  error: '',
  info: {},
};

const CreateUrlSegment = async (advertiserId, name, targets) => {
  let urlList = [];
  for (const target of targets) {
    urlList.push({ url: target['url'] });
  }
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  const request = {
    name: name,
    advertiser_id: advertiserId,
    url_list: urlList,
  };
  await axios
    .post(getCatalyticsApiUrl() + 'create-url-segments', request, {
      headers: {
        Authorization: `Basic ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(async (responseCreate) => {
      if (responseCreate.data === undefined) {
        urlSegmentResponse.error =
          'Empty response from POST url segment service';
        ApiException(
          urlSegmentResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      if (
        responseCreate.data['is_error'] !== undefined &&
        responseCreate.data['is_error']
      ) {
        urlSegmentResponse.error =
          'Error response from POST url segment service';
        ApiException(
          urlSegmentResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      if (responseCreate.data['url_segment'] === undefined) {
        urlSegmentResponse.error =
          'Segment information not found on response from url segment service';
        ApiException(
          urlSegmentResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      if (responseCreate.data['url_segment']['segment_id'] === undefined) {
        urlSegmentResponse.error =
          'Segment id not found on response from url segment service';
        ApiException(
          urlSegmentResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          request,
          responseCreate
        );
        return;
      }
      urlSegmentResponse.status = true;
      urlSegmentResponse.info = responseCreate.data['url_segment'];
    })
    .catch(function (error) {
      urlSegmentResponse.error = error;
      ApiException(
        urlSegmentResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        request,
        {}
      );
    });
  return urlSegmentResponse;
};

export default CreateUrlSegment;
