import JSZip from "jszip";

export const handleExtractDimensions = async (zipFile) => {
    const zip = new JSZip();
    // Load the ZIP file
    const loadedZip = await zip.loadAsync(zipFile);
    let indexHtmlPath = '';
    let indexCssPath = '';  
  
    // Find index.html and index.css
    loadedZip.forEach((relativePath, file) => {
      if (relativePath.endsWith('index.html')) {
        indexHtmlPath = relativePath;
      } else if (relativePath.endsWith('style.css')) {
        indexCssPath = relativePath;
      }
    }); 
      console.log("call")
  
    const indexHtml = loadedZip.file(indexHtmlPath);
    if (!indexHtml) {
      alert('index.html not found in the zip file');
      return;
    }
        
    // Read the content of index.html
    const htmlContent = await indexHtml.async('text')                                   
    // Load index.html into an iframe   
    const iframe = document.createElement('iframe');  
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    iframe.srcdoc = htmlContent;
  
    // Wait for iframe to load
    await new Promise(resolve => iframe.onload = resolve);
  
    // Check for inline dimensions
    const adContainer = iframe.contentDocument.querySelector('div, iframe');
    if (adContainer) {
      let width = adContainer.getAttribute('width') || adContainer.style.width;
      let height = adContainer.getAttribute('height') || adContainer.style.height;
  
      if (!width || !height) {
        // Look for dimensions in header CSS  
        const styles = iframe.contentDocument.querySelectorAll('style');
        for (const style of styles) {
          const cssText = style.textContent;
          const classMatch = adContainer.className && new RegExp(`.${adContainer.className}\\s*{[^}]*}`, 'i').exec(cssText);
          if (classMatch) {
            const widthMatch = /width\s*:\s*([\d.]+(px|%|em|rem|vh|vw))/i.exec(classMatch[0]);
            const heightMatch = /height\s*:\s*([\d.]+(px|%|em|rem|vh|vw))/i.exec(classMatch[0]);
            width = width || (widthMatch ? widthMatch[1] : null);
            height = height || (heightMatch ? heightMatch[1] : null);
            if (width && height) break;
          }
        }
      }
  
      if ((!width || !height) && indexCssPath) {
        // Load index.css if dimensions are still not found
        const indexCss = await loadedZip.file(indexCssPath).async('text');
        const classMatch = adContainer.className && new RegExp(`.${adContainer.className}\\s*{[^}]*}`, 'i').exec(indexCss);
        if (classMatch) {
          const widthMatch = /width\s*:\s*([\d.]+(px|%|em|rem|vh|vw))/i.exec(classMatch[0]);
          const heightMatch = /height\s*:\s*([\d.]+(px|%|em|rem|vh|vw))/i.exec(classMatch[0]);
          width = width || (widthMatch ? widthMatch[1] : null);
          height = height || (heightMatch ? heightMatch[1] : null);
        }
      }
  
      document.body.removeChild(iframe); // Clean up the iframe           
      if (width && height) {
        return { width, height };   
      } else {
        alert('Ad dimensions not found');
      }
    } else {
      alert('Ad container not found');
    }
  };

  export const handleImageDimensions = async (file) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        resolve({ width: image.width, height: image.height });
      };
      image.onerror = reject;
      image.src = URL.createObjectURL(file);
    });
  };

  export const handleVideoDimensions = async (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = reject;
      video.src = URL.createObjectURL(file);
    });
  };
  

  export const formatDimensions=(dim)=> {
    const width = dim?.width?.replace('px', '');
    const height = dim?.height?.replace('px', '');
    return `${width} x ${height}`;
  }

  export const extractUrlFromFileName = (file) => {
    if (!file?.name) {
      console.error("Invalid file object.");
      return { name: '', url: '' };
    }
  
    try {
      const fileName = file.name.split('?')[0];
      const url = decodeURIComponent(new URLSearchParams(file.name.split('?')[1]).get('url') || '');
  
      console.log(file, "file.name");
      console.log(fileName, url, "match");
      
      return { name: fileName, url };
    } catch (error) {
      console.error("Error extracting URL and name from file:", error.message);
      return { name: '', url: '' }; 
    }
  };
  
