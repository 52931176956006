import React from 'react';

function SettingsIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 9.32291V6.67706L13.8306 6.24972C13.7124 5.85644 13.5546 5.47663 13.3593 5.11506L14.5694 3.29984L12.7001 1.43062L10.8849 2.64075C10.5234 2.44541 10.1435 2.28762 9.75025 2.16941L9.32291 0H6.67709L6.24972 2.16937C5.85647 2.28759 5.47662 2.44537 5.11503 2.64072L3.29988 1.43062L1.43062 3.29988L2.64075 5.11509C2.44544 5.47666 2.28762 5.85647 2.16941 6.24975L0 6.67709V9.32294L2.16937 9.75031C2.28759 10.1436 2.44541 10.5234 2.64072 10.885L1.43059 12.7002L3.29984 14.5694L5.11503 13.3593C5.47659 13.5547 5.85647 13.7124 6.24972 13.8307L6.67709 16H9.32291L9.75028 13.8306C10.1436 13.7124 10.5234 13.5546 10.885 13.3593L12.7002 14.5694L14.5694 12.7002L13.3593 10.8849C13.5546 10.5234 13.7124 10.1436 13.8306 9.75028L16 9.32291ZM13.363 12.5807L12.5807 13.363L10.9157 12.2531C10.0709 12.7652 9.79097 12.8626 8.94425 13.0718L8.55209 15.0625H7.44791L7.05575 13.0718C6.09222 12.8338 5.82553 12.7024 5.08425 12.2531L3.41931 13.363L2.63697 12.5807L3.74694 10.9157C3.23134 10.0652 3.13578 9.78413 2.92819 8.94425L0.9375 8.55209V7.44794L2.92816 7.05578C3.13291 6.22734 3.22672 5.94241 3.74691 5.08428L2.63694 3.41934L3.41928 2.637L5.08422 3.74694C5.93597 3.23069 6.21744 3.13531 7.05572 2.92819L7.44791 0.9375H8.55209L8.94425 2.92819C9.90966 3.16672 10.1761 3.29856 10.9157 3.74694L12.5807 2.637L13.363 3.41934L12.2531 5.08428C12.7641 5.92738 12.8621 6.20734 13.0718 7.05578L15.0625 7.44791V8.55206L13.0718 8.94422C12.8332 9.90966 12.7021 10.175 12.2531 10.9157L13.363 12.5807Z"
        fill="#8C96A7"
      />
      <path
        d="M8 4.71875C6.19072 4.71875 4.71875 6.19072 4.71875 8C4.71875 9.80928 6.19072 11.2812 8 11.2812C9.80928 11.2812 11.2812 9.80928 11.2812 8C11.2812 6.19072 9.80928 4.71875 8 4.71875ZM8 10.3438C6.70766 10.3438 5.65625 9.29234 5.65625 8C5.65625 6.70766 6.70766 5.65625 8 5.65625C9.29234 5.65625 10.3438 6.70766 10.3438 8C10.3438 9.29234 9.29234 10.3438 8 10.3438Z"
        fill="#8C96A7"
      />
    </svg>
  );
}

export default SettingsIcon;
