import React from 'react';

function Template() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0V31.8887H44V0H0ZM40 28H4V4H40V28Z" fill="#131B23" />
      <path d="M44 35.5558H0V44H44V35.5558Z" fill="#131B23" />
    </svg>
  );
}

export default Template;
