import React, { useEffect, useContext, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { UserContext } from '../../../context/UserContext';
import CryptoJS from 'crypto-js';
import AppIndex from '../../app-index/AppIndex';
import AuthIndex from '../../auth/auth-index/AuthIndex';
import PageLoader from '../../util/page-loader/PageLoader';
import { AccountProvider } from '../../../context/AccountContext';
import { CampaignProvider } from '../../../context/CampaignContext';

const AuthLogic = () => {
  const [authAlertMessage, setAuthAlertMessage] = useState('');
  const [pageLoaded, setPageLoaded] = useState(false);
  const { userInfo } = useContext(UserContext);
  //console.log('authlogic line 39 - userInfo:', userInfo);
  function generatePassword() {
    var length = 8,
      charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
    }
  }, [pageLoaded]);

  return userInfo?.authView !== 'authenticated' ? (
    <AuthIndex />
  ) : (
    <AccountProvider>
      <CampaignProvider>
        <AppIndex />
      </CampaignProvider>
    </AccountProvider>
  );
};

export default AuthLogic;
