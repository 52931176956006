import React, { useState, useEffect, useLayoutEffect, Component } from 'react';
//import { Auth } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import { fetchAuthSession } from 'aws-amplify/auth';
import TrackingPixel from '../../api/services/tracking-pixel/TrackingPixel';
import Arrow from '../../assets/icons/arrow.svg';
import './tracking.css';
import { fetchUserAttributes } from 'aws-amplify/auth';

const Tracking = () => {
  const [universalPixelInfo, setUniversalPixelInfo] = useState();
  const [copyScript, setCopyScript] = useState('Pixel loading...');
  const [height, setHeight] = useState('50px');
  const [display, setDisplay] = useState('collapsed');
  const getUniversalPixel = async (advertiserId, name) => {
    let currentUserInfo = await fetchUserAttributes();
    if (
      currentUserInfo['attributes'] !== undefined ||
      currentUserInfo['attributes']['custom:AdvertiserId'] !== undefined ||
      currentUserInfo['attributes']['custom:AdvertiserId'] !== ''
    ) {
      advertiserId = currentUserInfo['attributes']['custom:AdvertiserId'];
    }
    advertiserId = currentUserInfo['attributes']['custom:AdvertiserId'];
    const trackingPixelResponse = await TrackingPixel(advertiserId, name);
    if (!trackingPixelResponse.status) {
      console.log(trackingPixelResponse.status);
      return;
    }
    setUniversalPixelInfo(trackingPixelResponse.info.universal_pixel.uuid);
    const elem = document.createElement('textarea');
    setCopyScript(
      `<script>!function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);pixie("init", "${universalPixelInfo}");</script><script>pixie("event", "PageView");</script><noscript><img src="https://ib.adnxs.com/pixie?pi=${universalPixelInfo}&e=PageView&script=0" width="1" height="1" style="display:none"/></noscript>`
    );
    return trackingPixelResponse.info;
  };
  useLayoutEffect(() => {
    getUniversalPixel();
    return;
  }, [copyScript]);

  const handleCopy = () => {
    navigator.clipboard.writeText(copyScript);
    let copy_confirm = document.createTextNode(
      'Tracking script copied to clipboard'
    );
    let copy_button = document.getElementById('copy_button');
    copy_button.appendChild(copy_confirm);
  };

  const handleExpand = (e) => {
    let currentEl = e.target.closest(`div`);
    console.log(currentEl);
    //document.getElementsByClassName('expandable-container')[0].style.height = 'auto';
    //currentEl.classList.add('')
    setDisplay('expanded');
  };

  useEffect(() => {
    // const toggleClass = (element, event) => {
    //     console.log(event.target)
    //     if (element.classList == 'expanded') {
    //         element.classList.remove('expanded')
    //     }
    //     else {
    //         element.classList.add('expanded')
    //     }
    // }
    // let expanders = document.querySelectorAll('.expandable-container')
    // let i = 0;
    // expanders.forEach(element => {element.addEventListener('click', toggleClass)})
    // if (expanders.length > 0 && i <= expanders.length) {
    //     expanders[i].addEventListener('click', toggleClass )
    //     i++;
    //     return
    // }
    // let i = 0;
    // const toggleClass = () => {
    //     let expanders = document.querySelectorAll('.expandable-container')
    //     const classes = expanders[i].classList;
    //     const result = classes.toggle("expanded");
    //     if (result) {
    //         console.log(`'expanded' added; classList is now "${classes}" and result is "${result}"`);
    //     } else {
    //         console.log(`'expanded' removed; classList is now "${classes}" and result is "${result}"`);
    //     }
    // }
  });

  return (
    <div id="tracking_body">
      <h2>Conversion Tracking</h2>
      <h4>Placement Instructions</h4>
      <p className="first-paragraph">
        The code below must be placed on your website in order to track visits
        and conversions from users that have seen your ads.
      </p>
      <p>
        Below are instructions to install tracking using Google Tag Manager.
      </p>
      {/* <ul>
                <li>Google Tag Manager</li>
                <li>Pasting the script into your website directly</li>
                <li>The React helmet plugin</li>
            </ul>
            <p>Please follow the instruction set that </p> */}
      <h4>Copy the code below to your clipboard:</h4>
      <div id="copy_button">
        <button id="copy_script" onClick={handleCopy}>
          Copy
        </button>
      </div>
      <div id="code_container">
        <p>{copyScript}</p>
      </div>
      <div className="instructions">
        <div
          className="expandable-container expanded"
          id="tag_manager_instructions"
        >
          <div className="expandable-container-heading">
            <img
              className="expand-icon"
              src={Arrow}
              alt="open container arrow"
            />
            <h4>Install Using Google Tag Manager</h4>
          </div>
          <p>
            Tag managers are code containers that allow users to place multiple
            tracking codes on a website without editing the website code itself.
            Tracking codes (sometimes called "scripts" or "pixels") can be added
            to a container from the tag manager UI and will be then be loaded
            into the website based on "firing rules" or "triggers" that you
            define.
          </p>
          <div className="sidenote">
            <h5>If you're having trouble with the jargon...</h5>
            <ul>
              <li>
                The reason so many terms are used interchangeably for tracking
                codes/scripts/pixels is that they are all correct in most
                circumstances. A "script" is a snippet of code. The function of
                the script in the context of media tracking is to request a 1px
                x 1px (very tiny) image file. This request allows the platform
                to recognize a viewer who sees one of your ads if he or she
                visits your website.
              </li>
              <li>
                "Firing rules" or "triggers" are listeners on your website that
                execute a function when a condition is met. This condition could
                be a URL match (e.g. "url contains 'thank-you'") or an event,
                like a button click or form submission. Setting these up will
                require some understanding of the technical vocabulary.
              </li>
            </ul>
          </div>
          <ol>
            <li>Log into tag manager and select your account</li>
            <li>Click 'Tags' in the left navigation</li>
            <li>Click 'New' at the top right of the center section</li>
            <li>
              Name your tag 'catalytics.js' (or any other name you choose)
            </li>
            <li>
              When the menu appears to choose a tag type, choose 'Custom HTML'
            </li>
            <li>
              Paste your snippet in the window (make sure you have maintained
              the{' '}
              <pre>
                <script> </script>
              </pre>{' '}
              tags that bookend the code)
            </li>
            <li>Scroll down to 'Triggering' and click on the panel</li>
            <li>Click the + icon at the top right to create a new trigger</li>
            <li>Name your trigger Window Loaded.</li>
            <li>
              Click the 'Trigger Configuration' menu then look for 'Window
              Loaded' in the menu and select it
            </li>
            <li>Click 'Save' to return to your tag</li>
            <li>
              Click save again on your tag to exit the configuration. Note that
              your tag is NOT YET published.
            </li>
            <li>
              You should now be back to your list of tags. Click 'Submit' at the
              top right. This will publish your new tag to your website.
            </li>
            <li>
              Name the version 'catalytics.js 1.0' and include any description
              you would like
            </li>
            <li>Click 'Publish'</li>
          </ol>
          <p>...</p>
          <p>
            Follow these instructions to preview and debug:{' '}
            <a href="https://support.google.com/tagmanager/answer/6107056?hl=en">
              https://support.google.com/tagmanager/answer/6107056?hl=en
            </a>
          </p>
          <p>
            If you have not yet set up tag manager on your website, instructions
            are available here:
            <a href="https://support.google.com/tagmanager/answer/6103696?hl=en">
              https://support.google.com/tagmanager/answer/6103696?hl=en
            </a>
          </p>
        </div>
        {/* <div className="expandable-container expanded" id="site_installation_instructions">
                    <div className = "expandable-container-heading">
                        <img className="expand-icon" src={Arrow} alt="open container arrow" />
                        <h4>Install by Adding the Code to your Website</h4>
                    </div>
                    <ol>
                        <li>Paste the code snippet above inside the closing body tag <pre>`body`</pre> on every page you would like to track:</li>
                        <li>If your site was built using a framework like react, you can paste the tracking snippet inside a component that loads once on every page (e.g. the footer) OR load the tracking code using a library like https://www.npmjs.com/package/react-tracking.</li>
                        <li><pre><script>'Code goes here'</script></pre></li>
                        <li>However you decide to load your tracking on your site, it is best if the code does not fire until after the page content has been loaded. This makes it easier to track interactions with page elements like buttons and forms.</li>
                        <li></li>
                    </ol>
                </div> */}
        {/* <div className="expandable-container expanded" id="helmet_installation_instructions">
                    <div className = "expandable-container-heading">
                        <img className="expand-icon" src={Arrow} alt="open container arrow" />
                        <h4>Install Using a React Plugin</h4>
                    </div>
                </div> */}
      </div>
    </div>
  );
};
export default Tracking;
