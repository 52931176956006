import React from 'react';

function ControlRoomIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 3H7.5V4H14V3Z" fill="#8C96A7" />
      <path d="M14 12H7.5V13H14V12Z" fill="#8C96A7" />
      <path d="M8.5 7.5H2V8.5H8.5V7.5Z" fill="#8C96A7" />
      <path
        d="M3.5 5.5C3.10444 5.5 2.71776 5.3827 2.38886 5.16294C2.05996 4.94318 1.80362 4.63082 1.65224 4.26537C1.50087 3.89992 1.46126 3.49778 1.53843 3.10982C1.6156 2.72186 1.80608 2.36549 2.08579 2.08579C2.36549 1.80608 2.72186 1.6156 3.10982 1.53843C3.49778 1.46126 3.89992 1.50087 4.26537 1.65224C4.63082 1.80362 4.94318 2.05996 5.16294 2.38886C5.3827 2.71776 5.5 3.10444 5.5 3.5C5.5 4.03043 5.28929 4.53914 4.91421 4.91421C4.53914 5.28929 4.03043 5.5 3.5 5.5ZM3.5 2.5C3.30222 2.5 3.10888 2.55865 2.94443 2.66853C2.77998 2.77841 2.65181 2.93459 2.57612 3.11732C2.50043 3.30004 2.48063 3.50111 2.51922 3.69509C2.5578 3.88907 2.65304 4.06726 2.79289 4.20711C2.93275 4.34696 3.11093 4.4422 3.30491 4.48079C3.49889 4.51937 3.69996 4.49957 3.88268 4.42388C4.06541 4.34819 4.22159 4.22002 4.33147 4.05557C4.44135 3.89112 4.5 3.69778 4.5 3.5C4.5 3.23478 4.39464 2.98043 4.20711 2.79289C4.01957 2.60536 3.76522 2.5 3.5 2.5Z"
        fill="#8C96A7"
      />
      <path
        d="M3.5 14.5C3.10444 14.5 2.71776 14.3827 2.38886 14.1629C2.05996 13.9432 1.80362 13.6308 1.65224 13.2654C1.50087 12.8999 1.46126 12.4978 1.53843 12.1098C1.6156 11.7219 1.80608 11.3655 2.08579 11.0858C2.36549 10.8061 2.72186 10.6156 3.10982 10.5384C3.49778 10.4613 3.89992 10.5009 4.26537 10.6522C4.63082 10.8036 4.94318 11.06 5.16294 11.3889C5.3827 11.7178 5.5 12.1044 5.5 12.5C5.5 13.0304 5.28929 13.5391 4.91421 13.9142C4.53914 14.2893 4.03043 14.5 3.5 14.5ZM3.5 11.5C3.30222 11.5 3.10888 11.5587 2.94443 11.6685C2.77998 11.7784 2.65181 11.9346 2.57612 12.1173C2.50043 12.3 2.48063 12.5011 2.51922 12.6951C2.5578 12.8891 2.65304 13.0673 2.79289 13.2071C2.93275 13.347 3.11093 13.4422 3.30491 13.4808C3.49889 13.5194 3.69996 13.4996 3.88268 13.4239C4.06541 13.3482 4.22159 13.22 4.33147 13.0556C4.44135 12.8911 4.5 12.6978 4.5 12.5C4.5 12.2348 4.39464 11.9804 4.20711 11.7929C4.01957 11.6054 3.76522 11.5 3.5 11.5Z"
        fill="#8C96A7"
      />
      <path
        d="M12.5 10C12.1044 10 11.7178 9.8827 11.3889 9.66294C11.06 9.44318 10.8036 9.13082 10.6522 8.76537C10.5009 8.39992 10.4613 7.99778 10.5384 7.60982C10.6156 7.22186 10.8061 6.86549 11.0858 6.58579C11.3655 6.30608 11.7219 6.1156 12.1098 6.03843C12.4978 5.96126 12.8999 6.00087 13.2654 6.15224C13.6308 6.30362 13.9432 6.55996 14.1629 6.88886C14.3827 7.21776 14.5 7.60444 14.5 8C14.5 8.53043 14.2893 9.03914 13.9142 9.41421C13.5391 9.78929 13.0304 10 12.5 10ZM12.5 7C12.3022 7 12.1089 7.05865 11.9444 7.16853C11.78 7.27841 11.6518 7.43459 11.5761 7.61732C11.5004 7.80004 11.4806 8.00111 11.5192 8.19509C11.5578 8.38907 11.653 8.56726 11.7929 8.70711C11.9327 8.84696 12.1109 8.9422 12.3049 8.98079C12.4989 9.01937 12.7 8.99957 12.8827 8.92388C13.0654 8.84819 13.2216 8.72002 13.3315 8.55557C13.4414 8.39112 13.5 8.19778 13.5 8C13.5 7.73478 13.3946 7.48043 13.2071 7.29289C13.0196 7.10536 12.7652 7 12.5 7Z"
        fill="#8C96A7"
      />
    </svg>
  );
}

export default ControlRoomIcon;
