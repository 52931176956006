import axios from 'axios';
import { UserContext } from '../../../../../context/UserContext';
import { useContext } from 'react';
import { ApiException } from '../../../logging/api/api-exception/apiException';
import getCatalyticsApiUrl from '../../../../../data/Urls';

let paymentIntentResponse = {
  status: false,
  error: '',
  info: {},
};

const GetPaymentIntent = async (paymentIntent) => {
  const { userInfo: currentUserInfo } = useContext(UserContext);
  const { username, id, signInDetails } = currentUserInfo;
  const { accessToken, idToken } = currentUserInfo;
  await axios
    .get(getCatalyticsApiUrl() + 'stripe-payment-intent', {
      params: {
        paymentIntent: paymentIntent,
        env:
          process.env.REACT_APP_BUILD_ENV === 'development'
            ? 'development'
            : '',
      },
      headers: {
        Authorization: `Basic ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then(async (responseGet) => {
      if (
        responseGet.data === undefined ||
        responseGet.data.body === undefined
      ) {
        paymentIntentResponse.error = 'Empty response from GET payment intent';
        ApiException(
          paymentIntentResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          responseGet
        );
      }
      let body = JSON.parse(responseGet['data']['body']);
      if (
        body['paymentIntentInfo'] === undefined ||
        body['paymentIntentInfo']['status'] === undefined
      ) {
        paymentIntentResponse.error =
          'Payment Intent info not returned from GET payment intent';
        ApiException(
          paymentIntentResponse.error,
          { email: currentUserInfo['attributes']['email'] },
          {},
          responseGet
        );
      }
      let paymentIntentStatus = body['paymentIntentInfo']['status'];
      if (paymentIntentStatus !== 'succeeded') {
        switch (paymentIntent.status) {
          /*TODO: define what to do if status of the payment is processing */
          case 'processing':
            paymentIntentResponse.error =
              'Payment Intent info returned processing';
            ApiException(
              paymentIntentResponse.error,
              { email: currentUserInfo['attributes']['email'] },
              {},
              responseGet
            );
            return;
          case 'requires_payment_method':
            paymentIntentResponse.error =
              'Payment Intent info returned requires_payment_method';
            ApiException(
              paymentIntentResponse.error,
              { email: currentUserInfo['attributes']['email'] },
              {},
              responseGet
            );
            return;
          default: /* unknown error */
            paymentIntentResponse.error =
              'Payment Intent info returned unknown error';
            ApiException(
              paymentIntentResponse.error,
              { email: currentUserInfo['attributes']['email'] },
              {},
              responseGet
            );
            return;
        }
      }
      paymentIntentResponse.status = true;
      paymentIntentResponse.info = body['paymentIntentInfo'];
    })
    .catch(function (error) {
      paymentIntentResponse.error = error;
      ApiException(
        paymentIntentResponse.error,
        { email: currentUserInfo['attributes']['email'] },
        {},
        {}
      );
    });
  return paymentIntentResponse;
};

export default GetPaymentIntent;
