import React, { useEffect, useState, useContext } from 'react';
import { Amplify } from 'aws-amplify';
import AuthLogic from './auth/auth-components/AuthLogic';
import { UserProvider } from '../context/UserContext';

import config from '../aws-exports.js'
Amplify.configure(config);
// const existingConfig = Amplify.getConfig();

// Amplify.configure({
//   ...existingConfig,
//   API: {
//     ...existingConfig.API,
//     REST: {
//       ...existingConfig.API?.REST,
//       Catalytics_STAGING_API: {
//         endpoint:
//           'https://2tsd2vn37a.execute-api.us-east-2.amazonaws.com/prod',
//           region: 'us-east-2'
//       }
//     }
//   }
// });

const App = () => {
  const [authState, setAuthState] = useState();
  const [authAlertMessage, setAuthAlertMessage] = useState('');
  const [pageLoaded, setPageLoaded] = useState(false);
  const [verifySignUp, setVerifySignUp] = useState(false);
  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');
  // function generatePassword() {
  //   var length = 8,
  //     charset =
  //       'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  //     retVal = '';
  //   for (var i = 0, n = charset.length; i < length; ++i) {
  //     retVal += charset.charAt(Math.floor(Math.random() * n));
  //   }
  //   return retVal;
  // }

  // const validateEmail = (email) => {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (!re.test(String(email).toLowerCase())) {
  //     return false;
  //   }
  //   return true;
  // };


  useEffect(() => {
    setTimeout(
      function () {
        if (!pageLoaded) {
          setPageLoaded(true);
        }
      },
      window.location.pathname.indexOf('/start-trial') !== -1 ? 5000 : 2000
    );
  }, [pageLoaded]);

  const updateAuthState = (newAuthState) => {
    setAuthState(newAuthState);
  };

  return (
    <UserProvider>
      <AuthLogic />
    </UserProvider>
  );
};

export default App;
