import React, { useContext } from 'react';
import './selected-targets.css';
import { CampaignContext } from '../../../context/CampaignContext';
import Tabs from '../../util/tabs/Tabs';

const SelectedTargets = (props) => {
  const { removeFromTargets, countBusinessTargets, countArticleTargets } =
    useContext(CampaignContext);

  /*const closeDrawerHandler = () => {
        props.closeHandler();
    }*/

  /*const selectedTabHandler = (index) => {
        setSelectedTab(index);
    }*/

  /*const removeFromTargets = (type, row) => {
        let newSelectedRows = []
        targets.forEach((selectedRow) => {
            if (type === 'business-search') {
                if (selectedRow['place_id'] !== undefined && (row['place_id'] === selectedRow['place_id'])) {
                } else {
                    newSelectedRows.push(selectedRow);

                }
            } else {
                if (selectedRow['url'] !== undefined && (row['url'] === selectedRow['url'])) {
                } else {
                    newSelectedRows.push(selectedRow)
                }
            }

        });
        updateCampaign({targets: newSelectedRows});

    }*/
  /*<div className="drawer-window">
        <div className="close-drawer" onClick={() => closeDrawerHandler()}>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path
                    d="M378.4 440.6c8.531 10.16 7.203 25.28-2.938 33.81C370.9 478.2 365.5 480 360 480c-6.844 0-13.64-2.906-18.39-8.562L192 293.3l-149.6 178.1C37.63 477.1 30.83 480 23.98 480c-5.453 0-10.92-1.844-15.42-5.625c-10.14-8.531-11.47-23.66-2.938-33.81L160.7 256L5.625 71.44C-2.906 61.28-1.578 46.16 8.563 37.63C18.69 29.08 33.84 30.39 42.38 40.56L192 218.7l149.6-178.1c8.547-10.17 23.67-11.47 33.81-2.938s11.47 23.66 2.938 33.81L223.3 256L378.4 440.6z"/>
            </svg>
        </div>
        <div className="selected-targets-container">
        <h3>Selected Targets</h3>*/

  const tabContentGenerator = (isBusinessSearching) => (
    <table>
      <tbody>
        {props.targets.map((target, index) => {
          target[isBusinessSearching ? 'place_id' : 'url'] !== undefined && (
            <tr key={index}>
              <td>{target[isBusinessSearching ? 'name' : 'title']}</td>
              <td className="action">
                <button
                  onClick={() =>
                    removeFromTargets(
                      isBusinessSearching
                        ? 'business-search'
                        : 'keyword-search',
                      target
                    )
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M432 80h-82.38l-34-56.75C306.1 8.827 291.4 0 274.6 0H173.4C156.6 0 141 8.827 132.4 23.25L98.38 80H16C7.125 80 0 87.13 0 96v16C0 120.9 7.125 128 16 128H32v320c0 35.35 28.65 64 64 64h256c35.35 0 64-28.65 64-64V128h16C440.9 128 448 120.9 448 112V96C448 87.13 440.9 80 432 80zM171.9 50.88C172.9 49.13 174.9 48 177 48h94c2.125 0 4.125 1.125 5.125 2.875L293.6 80H154.4L171.9 50.88zM352 464H96c-8.837 0-16-7.163-16-16V128h288v320C368 456.8 360.8 464 352 464zM224 416c8.844 0 16-7.156 16-16V192c0-8.844-7.156-16-16-16S208 183.2 208 192v208C208 408.8 215.2 416 224 416zM144 416C152.8 416 160 408.8 160 400V192c0-8.844-7.156-16-16-16S128 183.2 128 192v208C128 408.8 135.2 416 144 416zM304 416c8.844 0 16-7.156 16-16V192c0-8.844-7.156-16-16-16S288 183.2 288 192v208C288 408.8 295.2 416 304 416z" />
                  </svg>
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div className="selected-targets">
      <Tabs
        defaultSelected={countArticleTargets > countBusinessTargets ? 1 : 0}
        tabOptions={[
          `Business (${countBusinessTargets})`,
          `Articles (${countArticleTargets})`,
        ]}
        tabContents={[tabContentGenerator(true), tabContentGenerator(false)]}
      />
    </div>
  );
};

export default SelectedTargets;
