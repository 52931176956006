export const extractUserInfo = (userInfo) => {
  const {
    email,
    email_verified,
    family_name,
    given_name,
    sub,
    'custom:Company': company,
    'custom:AdvertiserId': advertiserId,
  } = userInfo.attributes;

  const result = {
    email,
    email_verified,
    family_name,
    given_name,
    company: company,
    sub,
    advertiser_id: advertiserId ? advertiserId : 5852409,
    source_ip: '198.234.6.7.345',
  };
  return result;
};
